import api from 'core/api/api';
import { ZipCode } from 'types/dataModels';

/**
 * Get zip codes list for county and state
 * @function
 * @category API
 * @param {string} countyId County id vale
 * @param {string} stateId State id value
 * @returns {ZipCode[]}
 */
export const getZipCodesApi = async (
    countyId: number,
    stateId: number
): Promise<ZipCode[]> => {
    try {
        const response = await api.getWithCache<ZipCode[]>(
            `/api/zipcode?CountyId=${countyId}&StateId=${stateId}`
        );
        return response.data;
    } catch (err) {
        throw new Error(err.message);
    }
};

/**
 * Get zip code object by id
 * @function
 * @category API
 * @param {string} id Zip code id
 * @returns {ZipCode}
 */
export const getZipCodesByIdApi = async (id: number): Promise<ZipCode> => {
    try {
        const response = await api.get<ZipCode>(`/api/zipcode/${id}`);
        return response.data;
    } catch (err) {
        throw new Error(err.message);
    }
};
