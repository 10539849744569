import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import api from 'core/api';
import { AppThunk } from 'core/store/store';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { getAllExceptionsAndRequirementsThunk } from 'core/features/exceptions/exceptionsSlice';
import { SnackbarSeverity } from 'core/constants/common';
import {
    DocumentCategorySubCategoryDto,
    DocumentCode,
    DocumentNote,
    DocumentReference,
    ExamOrderKeyDocumentGroupType,
    FullPulseDocumentFile,
    NewKeyDocumentForm,
    PulseDocumentPartyField,
    PulseDocumentPartyFieldWithPara,
    UpdateKeyDocumentInstrument,
    KeyDocument,
    CreatePulseDocumentFileDto,
    DocumentCategorySubCategoryResponse,
    ExamOrderParentSuccessorDocumentGroupType
} from 'types/dataModels';
import { updateParagraph } from 'core/features/exceptions/exceptionsSlice';
import { FileUploadError } from 'core/helpers/errors';
import { setFileUploadError } from 'core/features/uploadDocumentForm/uploadDocumentFormSlice';
import { fetchExamOrderReviewStateThunk } from '../workbenchTabs/workbenchTabsSlice';
import {
    fetchExamOrderVestingData,
    setHasPulseFilesFlagOfVestingData
} from '../examOrderVesting/examOrderVestingSlice';
import {
    fetchExamOrderLegalDescriptionData,
    setHasPulseFilesFlagOfLegalData
} from '../examOrderLegalDescription/examOrderLegalDescriptionSlice';

interface ExamOrderDocumentGroupState {
    /**
     *exam order document group array
     */
    examOrderDocumentGroupOld: ExamOrderKeyDocumentGroupType[];
    /**
     *exam order parent successtor document group array
     */
    examOrderDocumentGroup: ExamOrderParentSuccessorDocumentGroupType[];
    referredDocument: {
        docId: string;
        groupId: string;
    } | null;
}

const initialState: ExamOrderDocumentGroupState = {
    examOrderDocumentGroupOld: [],
    examOrderDocumentGroup: [],
    referredDocument: null
};

const examOrderKeyDocumentGroupSlice = createSlice({
    name: 'examOrderKeyDocumentGroup',
    initialState,
    reducers: {
        /**
         * Set key document group data from BE to state
         * @param state Slice state
         * @param action Payload with list of document groups to set
         */
        setExamOrderKeyDocumentGroupData(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<ExamOrderKeyDocumentGroupType[]>
        ) {
            state.examOrderDocumentGroupOld = action.payload;
        },
        /**
         * Set parent successtor key document group data from BE to state
         * @param state Slice state
         * @param action Payload with list of document groups to set
         */
        setExamOrderParentSuccessorKeyDocumentGroupData(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<ExamOrderParentSuccessorDocumentGroupType[]>
        ) {
            state.examOrderDocumentGroup = action.payload;
        },
        /**
         * Set the tagged state of a key document
         * @param state Slice state
         * @param action Payload object with the document ID and isTagged value to assign to the document
         */
        setIsDocumentTagged(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{ docId: string; isTagged: boolean }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children and grand children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children, grand docuument if it is the target document
             * 3. Update includes
             *      a. isTagged
             *      b. taggedCount
             *      c. unTaggedCount
             */
            const { docId: targetDocId, isTagged } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                let taggedCount = 0;
                let unTaggedCount = 0;
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        document.isTagged = isTagged;
                    }
                    if (document.isTagged) ++taggedCount;
                    if (!document.isTagged) ++unTaggedCount;

                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            childDocument.isTagged = isTagged;
                        }
                        if (childDocument.isTagged) ++taggedCount;
                        if (!childDocument.isTagged) ++unTaggedCount;

                        childDocument?.childDocuments
                            ?.filter(Boolean)
                            ?.forEach((grandChildDocoument) => {
                                if (grandChildDocoument.id === targetDocId) {
                                    grandChildDocoument.isTagged = isTagged;
                                }
                                if (grandChildDocoument.isTagged) ++taggedCount;
                                if (!grandChildDocoument.isTagged) ++unTaggedCount;
                            });
                    });
                });
                group.taggedCount = taggedCount;
                group.unTaggedCount = unTaggedCount;
            });
        },
        /**
         * Set the tagged state of all key documents in a group
         * @param state Slice state
         * @param action Payload with the ID of the group and the isTagged value to assign to the documents
         */
        setAllDocsIsTagged(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{ groupId: string; isTagged: boolean }>
        ) {
            const targetGroup = state.examOrderDocumentGroup.find(
                (group) => group.groupId === action.payload.groupId
            );
            targetGroup.documents.forEach((doc) => (doc.isTagged = action.payload.isTagged));
            targetGroup.taggedCount = targetGroup.documents.reduce((count, doc) => {
                let currentCount = count;
                return doc.isTagged ? ++currentCount : currentCount;
            }, 0);
            targetGroup.unTaggedCount = targetGroup.documents.reduce((count, doc) => {
                let currentCount = count;
                return !doc.isTagged ? ++currentCount : currentCount;
            }, 0);

            targetGroup.documents.forEach((doc1) => {
                state.examOrderDocumentGroup.forEach((group) => {
                    if (group.groupId != action.payload.groupId) {
                        group.documents.forEach((doc2) => {
                            if (doc1.id == doc2.id) {
                                doc2.isTagged = action.payload.isTagged;
                                group.taggedCount = group.documents.reduce((count, doc) => {
                                    let currentCount = count;
                                    return doc.isTagged ? ++currentCount : currentCount;
                                }, 0);
                                group.unTaggedCount = group.documents.reduce((count, doc) => {
                                    let currentCount = count;
                                    return !doc.isTagged ? ++currentCount : currentCount;
                                }, 0);
                            }
                        });
                    }
                });
            });
        },
        /**
         * Set the untagged state of all child, grandchild documents of a parent document
         * @param state Slice state
         * @param action Payload with the ID of the group, documentIds, and the isTagged value to assign to the documents
         */
        setChildDocsIsUnTagged(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{
                groupId: string;
                documentIds: string[];
                isTagged: boolean;
            }>
        ) {
            const { documentIds, isTagged } = action.payload;

            state.examOrderDocumentGroup.forEach((group) => {
                // This change is applicable only to the current group.
                let taggedCount = 0;
                let unTaggedCount = 0;
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (documentIds.find((docId) => docId === document.id)) {
                        document.isTagged = isTagged;
                    }
                    if (document.isTagged) ++taggedCount;
                    if (!document.isTagged) ++unTaggedCount;

                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (documentIds.find((docId) => docId === childDocument.id)) {
                            childDocument.isTagged = isTagged;
                        }
                        if (childDocument.isTagged) ++taggedCount;
                        if (!childDocument.isTagged) ++unTaggedCount;

                        childDocument?.childDocuments
                            ?.filter(Boolean)
                            ?.forEach((grandChildDocoument) => {
                                if (
                                    documentIds.find(
                                        (docId) => docId === grandChildDocoument.id
                                    )
                                ) {
                                    grandChildDocoument.isTagged = isTagged;
                                }
                                if (grandChildDocoument.isTagged) ++taggedCount;
                                if (!grandChildDocoument.isTagged) ++unTaggedCount;
                            });
                    });
                });
                group.taggedCount = taggedCount;
                group.unTaggedCount = unTaggedCount;
            });
        },
        /**
         * Set the tagged state of all files attached to a key document
         * @param state Slice state
         * @param action Payload with the ID of the document and the isTagged value to assign to the files
         */
        setDocumentFilesIsTagged(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{ docId: string; isTagged: boolean }>
        ) {
            const targetGroupAr = state.examOrderDocumentGroup.filter((group) =>
                group.documents.map((doc) => doc.id).includes(action.payload.docId)
            );
            const targetDocument: Array<KeyDocument> = [];
            targetGroupAr.forEach((targetGroup) => {
                targetDocument.push(
                    targetGroup.documents.find((doc) => doc.id === action.payload.docId)
                );
            });
            targetDocument.forEach((item) => {
                item.files.forEach((file) => (file.isTagged = action.payload.isTagged));
            });
        },
        /**
         * Set the tagged state of all files attached to a key document
         * @param state Slice state
         * @param action Payload with the ID of the document and the includeAttachments value to assign to the files
         */
        setDocumentIncludeAttached(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{ docId: string; includeAttachments: boolean }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children and grand children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children, grand docuument if it is the target document
             * 3. Update includes
             *      a. includeAttachments
             */
            const { docId: targetDocId, includeAttachments } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        document.includeAttachments = includeAttachments;
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            childDocument.includeAttachments = includeAttachments;
                        }
                        childDocument?.childDocuments
                            ?.filter(Boolean)
                            ?.forEach((grandChildDocoument) => {
                                if (grandChildDocoument.id === targetDocId) {
                                    grandChildDocoument.includeAttachments =
                                        includeAttachments;
                                }
                            });
                    });
                });
            });
        },
        /**
         * Add a party to a key document
         * @param state Slice state
         * @param action Payload with the group ID, document ID and the new party to add
         */
        addParty(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{
                groupId: string;
                docId: string;
                newParty: PulseDocumentPartyField;
            }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children and grand children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children, grand docuument if it is the target document
             * 3. Update includes
             *      a. newParty
             */
            const { docId: targetDocId, newParty } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        if (document.parties?.length) {
                            document.parties?.push(newParty);
                        } else {
                            document.parties = [newParty];
                        }
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            if (childDocument.parties?.length) {
                                childDocument.parties?.push(newParty);
                            } else {
                                childDocument.parties = [newParty];
                            }
                        }
                        childDocument?.childDocuments
                            ?.filter(Boolean)
                            ?.forEach((grandChildDocoument) => {
                                if (grandChildDocoument.id === targetDocId) {
                                    if (grandChildDocoument.parties?.length) {
                                        grandChildDocoument.parties?.push(newParty);
                                    } else {
                                        grandChildDocoument.parties = [newParty];
                                    }
                                }
                            });
                    });
                });
            });
        },
        /**
         * Update a party in an existing key document
         * @param state Slice state
         * @param action Payload with the group ID, document ID, party ID and updated party name
         */
        updateParty(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{
                groupId: string;
                docId: string;
                partyId: string;
                value: PulseDocumentPartyFieldWithPara;
            }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children and grand children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children, grand docuument if it is the target document
             * 3. Update includes
             *      a. partyId
             *      b. value
             */
            const { docId: targetDocId, partyId, value } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        const targetPartyIndex = document.parties.findIndex(
                            (party) => party.id === partyId
                        );
                        if (
                            //TODO:
                            document.parties?.length
                        ) {
                            document.parties[targetPartyIndex] = value.field;
                        } else {
                            document.parties = [value.field];
                        }
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            const targetPartyIndex = childDocument.parties.findIndex(
                                (party) => party.id === partyId
                            );
                            if (
                                // TODO:
                                childDocument.parties?.length
                            ) {
                                childDocument.parties[targetPartyIndex] = value.field;
                            } else {
                                childDocument.parties = [value.field];
                            }
                        }
                        childDocument?.childDocuments
                            ?.filter(Boolean)
                            ?.forEach((grandChildDocoument) => {
                                if (grandChildDocoument.id === targetDocId) {
                                    const targetPartyIndex =
                                        grandChildDocoument.parties.findIndex(
                                            (party) => party.id === partyId
                                        );
                                    if (
                                        // TODO:
                                        grandChildDocoument.parties?.length
                                    ) {
                                        grandChildDocoument.parties[targetPartyIndex] =
                                            value.field;
                                    } else {
                                        grandChildDocoument.parties = [value.field];
                                    }
                                }
                            });
                    });
                });
            });
        },
        /**
         * Delete a party in an existing key document
         * @param state Slice state
         * @param action Payload with the group ID, document ID and party ID to delete
         */
        deleteParty(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{ groupId: string; docId: string; partyId: string }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children and grand children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children, grand docuument if it is the target document
             * 3. Update includes
             *      a. partyId
             */
            const { docId: targetDocId, partyId } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        const targetPartyIndex = document.parties.findIndex(
                            (party) => party.id === partyId
                        );
                        if (targetPartyIndex && document.parties?.length) {
                            document.parties.splice(targetPartyIndex, 1);
                        }
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            const targetPartyIndex = childDocument.parties.findIndex(
                                (party) => party.id === partyId
                            );
                            if (targetPartyIndex && childDocument.parties?.length) {
                                childDocument.parties.splice(targetPartyIndex, 1);
                            }
                        }
                        childDocument?.childDocuments
                            ?.filter(Boolean)
                            ?.forEach((grandChildDocoument) => {
                                if (grandChildDocoument.id === targetDocId) {
                                    const targetPartyIndex =
                                        grandChildDocoument.parties.findIndex(
                                            (party) => party.id === partyId
                                        );
                                    if (
                                        targetPartyIndex &&
                                        grandChildDocoument.parties?.length
                                    ) {
                                        grandChildDocoument.parties.splice(
                                            targetPartyIndex,
                                            1
                                        );
                                    }
                                }
                            });
                    });
                });
            });
        },
        /**
         * Update the instrument identifiers of a key document
         * @param state Slice state
         * @param action Payload with the document ID and updated values object
         */
        updateInstrumentComplexInput(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{
                docId: string;
                newValue: UpdateKeyDocumentInstrument;
            }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children and grand children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children, grand docuument if it is the target document
             * 3. Update includes
             *      a. instrumentYear
             *      b. instrumentNumber
             *      c. bookType
             *      d. liber
             *      e. page
             *      f. documentNumber
             */
            const { docId: targetDocId, newValue } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        document.instrumentYear = newValue.instrumentYear;
                        document.instrumentNumber = newValue.instrumentNumber;
                        document.bookType = newValue.bookType;
                        document.liber = newValue.liber;
                        document.page = newValue.page;
                        document.documentNumber = newValue.documentNumber;
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            childDocument.instrumentYear = newValue.instrumentYear;
                            childDocument.instrumentNumber = newValue.instrumentNumber;
                            childDocument.bookType = newValue.bookType;
                            childDocument.liber = newValue.liber;
                            childDocument.page = newValue.page;
                            childDocument.documentNumber = newValue.documentNumber;
                        }
                        childDocument?.childDocuments
                            ?.filter(Boolean)
                            ?.forEach((grandChildDocoument) => {
                                if (grandChildDocoument.id === targetDocId) {
                                    grandChildDocoument.instrumentYear =
                                        newValue.instrumentYear;
                                    grandChildDocoument.instrumentNumber =
                                        newValue.instrumentNumber;
                                    grandChildDocoument.bookType = newValue.bookType;
                                    grandChildDocoument.liber = newValue.liber;
                                    grandChildDocoument.page = newValue.page;
                                    grandChildDocoument.documentNumber =
                                        newValue.documentNumber;
                                }
                            });
                    });
                });
            });
        },
        /**
         * Update the recorded date of a key document
         * @param state Slice state
         * @param action Payload with the group ID, document ID and updated ISO date string
         */
        updateRecordedDate(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{
                groupId: string;
                docId: string;
                newDate: string;
            }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children and grand children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children, grand docuument if it is the target document
             * 3. Update includes
             *      a. recordedDate
             */
            const { docId: targetDocId, newDate } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        document.recordedDate = newDate;
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            childDocument.recordedDate = newDate;
                        }
                        childDocument?.childDocuments
                            ?.filter(Boolean)
                            ?.forEach((grandChildDocoument) => {
                                if (grandChildDocoument.id === targetDocId) {
                                    grandChildDocoument.recordedDate = newDate;
                                }
                            });
                    });
                });
            });
        },
        /**
         * Untag the image of a key document
         * @param state Slice state
         * @param action Payload with the document ID to delete the image from
         */
        deleteDocumentImage(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{
                docId: string;
            }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children and grand children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children, grand docuument if it is the target document
             * 3. Update includes
             *      a. hasPulseFiles
             *      b. files
             *      c. isOcr
             *      d. includeAttachments
             */
            const { docId: targetDocId } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        document.hasPulseFiles = false;
                        document.files = [];
                        document.isOcr = false;
                        document.includeAttachments = false;
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            childDocument.hasPulseFiles = false;
                            childDocument.files = [];
                            childDocument.isOcr = false;
                            childDocument.includeAttachments = false;
                        }
                        childDocument?.childDocuments
                            ?.filter(Boolean)
                            ?.forEach((grandChildDocoument) => {
                                if (grandChildDocoument.id === targetDocId) {
                                    grandChildDocoument.hasPulseFiles = false;
                                    grandChildDocoument.files = [];
                                    grandChildDocoument.isOcr = false;
                                    grandChildDocoument.includeAttachments = false;
                                }
                            });
                    });
                });
            });
        },
        /**
         * Add references to a key document
         * @param state Slice state
         * @param action Payload with the document ID and references array to add
         */
        addDocumentReferences(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{
                docId: string;
                references: DocumentReference[];
            }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children and grand children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children, grand docuument if it is the target document
             * 3. Update includes
             *      a. documentReferences
             */
            const { docId: targetDocId, references } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        document.documentReferences = [...references];
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            childDocument.documentReferences = [...references];
                        }
                        childDocument?.childDocuments
                            ?.filter(Boolean)
                            ?.forEach((grandChildDocoument) => {
                                if (grandChildDocoument.id === targetDocId) {
                                    grandChildDocoument.documentReferences = [...references];
                                }
                            });
                    });
                });
            });
        },
        /**
         * set refered documents
         * @param state Slice state
         * @param action Payload with the document ID
         */
        setReferredDocuments(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<string>
        ) {
            const targetGroup = state.examOrderDocumentGroup.find((group) =>
                group.documents.some((doc) => doc.id === action.payload)
            );
            state.referredDocument = {
                docId: action.payload,
                groupId: targetGroup?.groupId
            };
        },
        /**
         * Delete a reference in a key document
         * @param state Slice state
         * @param action Payload with the document ID and ID of the reference to delete
         */
        deleteDocumentReference(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{
                docId: string;
                refId: string;
            }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children and grand children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children, grand docuument if it is the target document
             * 3. Update includes
             *      a. Find document reference index
             *      b. and remove
             */
            const { docId: targetDocId, refId } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        const targetReferenceIndex = document.documentReferences.findIndex(
                            (ref) => ref.documentReferenceId === refId
                        );
                        document.documentReferences.splice(targetReferenceIndex, 1);
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            const targetReferenceIndex =
                                childDocument.documentReferences.findIndex(
                                    (ref) => ref.documentReferenceId === refId
                                );
                            childDocument.documentReferences.splice(targetReferenceIndex, 1);
                        }
                        childDocument?.childDocuments
                            ?.filter(Boolean)
                            ?.forEach((grandChildDocoument) => {
                                if (grandChildDocoument.id === targetDocId) {
                                    const targetReferenceIndex =
                                        grandChildDocoument.documentReferences.findIndex(
                                            (ref) => ref.documentReferenceId === refId
                                        );
                                    grandChildDocoument.documentReferences.splice(
                                        targetReferenceIndex,
                                        1
                                    );
                                }
                            });
                    });
                });
            });
        },
        /**
         * Add codes to a key document
         * @param state Slice state
         * @param action Payload with the document ID and array of codes to add
         */
        addDocumentCodes(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{
                docId: string;
                codes: DocumentCode[];
            }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children and grand children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children, grand docuument if it is the target document
             * 3. Update includes
             *      a. codes
             */
            const { docId: targetDocId, codes } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        document.codes = [...codes];
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            childDocument.codes = [...codes];
                        }
                        childDocument?.childDocuments
                            ?.filter(Boolean)
                            ?.forEach((grandChildDocoument) => {
                                if (grandChildDocoument.id === targetDocId) {
                                    grandChildDocoument.codes = [...codes];
                                }
                            });
                    });
                });
            });
        },
        /**
         * Remove a code from a key document
         * @param state Slice state
         * @param action Payload with the document ID and ID of the code to remove
         */
        removeDocumentCode(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{
                docId: string;
                paragraphId: string;
            }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children and grand children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children, grand docuument if it is the target document
             * 3. Update includes
             *      a. Find code index
             *      b. and remove
             */
            const { docId: targetDocId, paragraphId } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        const targetCode = document.codes?.findIndex(
                            (el) => el.id === Number(paragraphId)
                        );
                        document.codes.splice(targetCode, 1);
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            const targetCode = childDocument.codes?.findIndex(
                                (el) => el.id === Number(paragraphId)
                            );
                            childDocument.codes.splice(targetCode, 1);
                        }
                        childDocument?.childDocuments
                            ?.filter(Boolean)
                            ?.forEach((grandChildDocoument) => {
                                if (grandChildDocoument.id === targetDocId) {
                                    const targetCode = grandChildDocoument.codes?.findIndex(
                                        (el) => el.id === Number(paragraphId)
                                    );
                                    grandChildDocoument.codes.splice(targetCode, 1);
                                }
                            });
                    });
                });
            });
        },
        /**
         * Update a file in a key document
         * @param state Slice state
         * @param action Payload with the document ID and the new file to set
         */
        updateDocumentFile(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{ docId: string; file: FullPulseDocumentFile }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children and grand children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children, grand docuument if it is the target document
             * 3. Update includes
             *      a. file
             */
            const { docId: targetDocId, file } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        document.files[0] = file;
                        document.hasPulseFiles = true;
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            childDocument.files[0] = file;
                            childDocument.hasPulseFiles = true;
                        }
                        childDocument?.childDocuments
                            ?.filter(Boolean)
                            ?.forEach((grandChildDocoument) => {
                                if (grandChildDocoument.id === targetDocId) {
                                    grandChildDocoument.files[0] = file;
                                    grandChildDocoument.hasPulseFiles = true;
                                }
                            });
                    });
                });
            });
        },
        /**
         * Update the note in a key document
         * @param state Slice state
         * @param action Payload with the document ID and the new note to set
         */
        updateDocumentNote(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{ docId: string; documentNote: DocumentNote }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children and grand children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children, grand docuument if it is the target document
             * 3. Update includes
             *      a. notes
             */
            const { docId: targetDocId, documentNote } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        const noteToBeUpdated = document.notes.findIndex(
                            (note) => note.id === documentNote.id
                        );
                        if (noteToBeUpdated !== -1) {
                            document.notes[noteToBeUpdated] = documentNote;
                        } else {
                            document.notes.push(documentNote);
                        }
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            const noteToBeUpdated = childDocument.notes.findIndex(
                                (note) => note.id === documentNote.id
                            );
                            if (noteToBeUpdated !== -1) {
                                childDocument.notes[noteToBeUpdated] = documentNote;
                            } else {
                                childDocument.notes.push(documentNote);
                            }
                        }
                        childDocument?.childDocuments
                            ?.filter(Boolean)
                            ?.forEach((grandChildDocoument) => {
                                if (grandChildDocoument.id === targetDocId) {
                                    const noteToBeUpdated =
                                        grandChildDocoument.notes.findIndex(
                                            (note) => note.id === documentNote.id
                                        );
                                    if (noteToBeUpdated !== -1) {
                                        grandChildDocoument.notes[noteToBeUpdated] =
                                            documentNote;
                                    } else {
                                        grandChildDocoument.notes.push(documentNote);
                                    }
                                }
                            });
                    });
                });
            });
        },
        /**
         * Remove a note in a key document
         * @param state Slice state
         * @param action Payload with the document ID and ID of the note to remove
         */
        removeDocumentNote(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{ docId: string; noteId: string }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children and grand children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children, grand docuument if it is the target document
             * 3. Update includes
             *      a. Find note index
             *      b. and remove
             */
            const { docId: targetDocId, noteId } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        const targetNoteIndex = document.notes.findIndex(
                            (note) => note.id === noteId
                        );
                        document.notes.splice(targetNoteIndex, 1);
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            const targetNoteIndex = childDocument.notes.findIndex(
                                (note) => note.id === noteId
                            );
                            childDocument.notes.splice(targetNoteIndex, 1);
                        }
                        childDocument?.childDocuments
                            ?.filter(Boolean)
                            ?.forEach((grandChildDocoument) => {
                                if (grandChildDocoument.id === targetDocId) {
                                    const targetNoteIndex =
                                        grandChildDocoument.notes.findIndex(
                                            (note) => note.id === noteId
                                        );
                                    grandChildDocoument.notes.splice(targetNoteIndex, 1);
                                }
                            });
                    });
                });
            });
        },
        /**
         * Update the amount field in a key document
         * @param state Slice state
         * @param action Payload with the document ID and the new amount to set
         */
        updateAmount(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{ docId: string; amount: number }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children and grand children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children, grand docuument if it is the target document
             * 3. Update includes
             *      a. amount
             */
            const { docId: targetDocId, amount } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        document.amount = amount;
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            childDocument.amount = amount;
                        }
                        childDocument?.childDocuments
                            ?.filter(Boolean)
                            ?.forEach((grandChildDocoument) => {
                                if (grandChildDocoument.id === targetDocId) {
                                    grandChildDocoument.amount = amount;
                                }
                            });
                    });
                });
            });
        },
        /**
         * Update the transfer tax amount field in a key document
         * @param state Slice state
         * @param action Payload with the document ID and the new amount to set
         */
        updateTransferTaxAmount(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{ docId: string; amount: number }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children and grand children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children, grand docuument if it is the target document
             * 3. Update includes
             *      a. transferTaxAmount
             */
            const { docId: targetDocId, amount } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        document.transferTaxAmount = amount;
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            childDocument.transferTaxAmount = amount;
                        }
                        childDocument?.childDocuments
                            ?.filter(Boolean)
                            ?.forEach((grandChildDocoument) => {
                                if (grandChildDocoument.id === targetDocId) {
                                    grandChildDocoument.transferTaxAmount = amount;
                                }
                            });
                    });
                });
            });
        },
        /**
         * Updates DocumentType data of document on category/subcategory change
         * @param state Slice state
         * @param action Payload with the document ID and DocumentType response
         */
        updateCategorySubCategoryOfDoc(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{
                docId: string;
                docTypeResponse: DocumentCategorySubCategoryResponse;
            }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children and grand children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children, grand docuument if it is the target document
             * 3. Update includes
             *      a. DocumentType object
             */
            const { docId: targetDocId, docTypeResponse: documentType } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        document.documentType = documentType;
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            childDocument.documentType = documentType;
                        }
                        childDocument?.childDocuments
                            ?.filter(Boolean)
                            ?.forEach((grandChildDocoument) => {
                                if (grandChildDocoument.id === targetDocId) {
                                    grandChildDocoument.documentType = documentType;
                                }
                            });
                    });
                });
            });
        }
    }
});

export const {
    setExamOrderKeyDocumentGroupData,
    setExamOrderParentSuccessorKeyDocumentGroupData,
    setIsDocumentTagged,
    setAllDocsIsTagged,
    setChildDocsIsUnTagged,
    setDocumentFilesIsTagged,
    setDocumentIncludeAttached,
    addParty,
    updateParty,
    deleteParty,
    updateInstrumentComplexInput,
    updateRecordedDate,
    deleteDocumentImage,
    addDocumentReferences,
    setReferredDocuments,
    addDocumentCodes,
    removeDocumentCode,
    updateDocumentFile,
    updateDocumentNote,
    removeDocumentNote,
    updateAmount,
    updateTransferTaxAmount,
    deleteDocumentReference,
    updateCategorySubCategoryOfDoc
} = examOrderKeyDocumentGroupSlice.actions;

/**
 * Fetch exam order document group data from BE
 * @param {string} orderId
 * @returns {AppThunk}
 */
export const fetchExamOrderDocumentGroupData =
    (orderId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.examOrderDocumentGroup.getExamOrderDocumentGroup(
                orderId
            );
            dispatch(setExamOrderKeyDocumentGroupData(response));
        } catch (err) {
            dispatch(setExamOrderKeyDocumentGroupData([]));
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get key documents groups: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Fetch exam order parent successors document group data from BE
 * @param {string} orderId
 * @returns {AppThunk}
 */
export const fetchExamOrderParentSuccessorsDocumentGroupData =
    (orderId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response =
                await api.examOrderDocumentGroup.getExamOrderParentSuccessorDocumentGroup(
                    orderId
                );
            /**
             *  Post processing on documents
             *      a. Remove null and undefined children
             *      b. Add unique number to each document which will be helpful:
             *          1. tab index focus
             *          2. parent-successtor dash relation line
             **/

            let tabIndex = 0;

            let updatedResponse = response?.map((group) => {
                return {
                    ...group,
                    documents: group.documents?.filter(Boolean)?.map((document) => {
                        return {
                            ...document,
                            childDocuments: document?.childDocuments
                                ?.filter(Boolean)
                                ?.map((childDocument) => {
                                    return {
                                        ...childDocument,
                                        childDocuments: childDocument?.childDocuments
                                            ?.filter(Boolean)
                                            ?.map((grandChildDocoument) => grandChildDocoument)
                                    };
                                })
                        };
                    })
                };
            });

            updatedResponse = updatedResponse?.map((group) => {
                return {
                    ...group,
                    documents: group.documents?.map((document) => {
                        return {
                            ...document,
                            tabIndex: ++tabIndex,
                            childDocuments: document?.childDocuments?.map((childDocument) => {
                                return {
                                    ...childDocument,
                                    tabIndex: ++tabIndex,
                                    childDocuments: childDocument?.childDocuments?.map(
                                        (grandChildDocoument) => {
                                            return {
                                                ...grandChildDocoument,
                                                tabIndex: ++tabIndex
                                            };
                                        }
                                    )
                                };
                            })
                        };
                    })
                };
            });

            dispatch(setExamOrderParentSuccessorKeyDocumentGroupData(updatedResponse));
        } catch (err) {
            dispatch(setExamOrderParentSuccessorKeyDocumentGroupData([]));
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get Parent successor key documents groups: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Add a party entity to an existing document
 * @param {string} orderId ID of the order
 * @param {string} groupId ID of the group where the document is included
 * @param {string} docId ID of the document to add a party to
 * @param {boolean} isGrantee Flag that describes if the party is a Grantee or Grantor
 * @param {string} partyRoleName Role of the new party
 * @returns {AppThunk}
 */
export const addDocumentPartyThunk =
    (
        orderId: string,
        groupId: string,
        docId: string,
        isGrantee: boolean,
        partyRoleName: string
    ): AppThunk =>
    async (dispatch) => {
        const newPartyObj: PulseDocumentPartyField = {
            id: uuidv4(),
            fieldNameId: uuidv4(),
            isGrantee: isGrantee,
            role: partyRoleName,
            first: '',
            middle: '',
            last: '',
            businessName: '',
            displayValue: ''
        };
        try {
            const response = await api.examOrderDocumentFields.addDocumentPartyApi(
                orderId,
                docId,
                newPartyObj
            );
            dispatch(addParty({ groupId, docId, newParty: response }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Add document party: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Remove a party entity from an existing document
 * @param {string} orderId ID of the order
 * @param {string} groupId ID of the group where the document is included
 * @param {string} docId ID of the document to remove a party from
 * @param {string} partyId ID of the party to remove
 * @returns {AppThunk}
 */
export const deleteDocumentPartyThunk =
    (orderId: string, groupId: string, docId: string, partyId: string): AppThunk =>
    async (dispatch) => {
        try {
            await api.examOrderDocumentFields.deleteDocumentPartyApi(orderId, docId, partyId);
            dispatch(deleteParty({ groupId, docId, partyId }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Remove document party: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update the value of a party in an existing document
 * @param {string} orderId ID of the order
 * @param {string} groupId ID of the group where the document is included
 * @param {string} docId ID of the document that owns the party
 * @param {string} partyId ID of the party to update
 * @param {string} value New value for the party
 * @returns {AppThunk}
 */
export const updateDocumentPartyThunk =
    (
        orderId: string,
        groupId: string,
        docId: string,
        partyId: string,
        value: string
    ): AppThunk =>
    async (dispatch) => {
        try {
            const response =
                await api.examOrderDocumentFields.updateDocumentPartyWithParagraphsApi(
                    orderId,
                    docId,
                    partyId,
                    value
                );
            dispatch(updateParty({ groupId, docId, partyId, value: response }));
            if (response.paragraphs.length > 0) {
                response.paragraphs.forEach((para) => {
                    dispatch(updateParagraph({ id: para.id, paragraph: para }));
                });
            }
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Update document party: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update the recorded date of an existing document,
 * this includes updated paragraphs that may be effected by an update to this field
 * @param {string} orderId ID of the order
 * @param {string} groupId ID of the group where the document is included
 * @param {string} docId ID of the document
 * @param {string} newDate ISO date string that represents the new recorded date of the document
 * @returns {AppThunk}
 */
export const updateRecordedDateThunk =
    (orderId: string, groupId: string, docId: string, newDate: string): AppThunk =>
    async (dispatch) => {
        try {
            const response =
                await api.examOrderDocumentFields.updateDocumentRecordedDateWithParagraphsApi(
                    orderId,
                    docId,
                    newDate
                );
            dispatch(updateRecordedDate({ groupId, docId, newDate }));
            if (response.paragraphs.length > 0) {
                response.paragraphs.forEach((para) => {
                    dispatch(updateParagraph({ id: para.id, paragraph: para }));
                });
            }
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Update recorded date: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Add a document reference to an existing document
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document to add a reference to
 * @param {string} referenceToDocumentId ID of the reference
 * @param {string} instrumentNumber Instrument number of the referenced document
 * @param {number} instrumentYear Instrument year of the referenced document
 * @param {string} liber Liber of the referenced document
 * @param {string} page Page of the referenced document
 * @returns {AppThunk}
 */
export const addOrderDocumentReferenceThunk =
    (
        orderId: string,
        documentId: string,
        referenceToDocumentId: string,
        instrumentNumber: string,
        instrumentYear: number,
        liber: string,
        page: string
    ): AppThunk =>
    async (dispatch) => {
        try {
            const newReferences = await api.examOrderReferences.addExamOrderDocumentReference(
                orderId,
                documentId,
                {
                    referenceToDocumentId,
                    instrumentNumber,
                    instrumentYear: Number(instrumentYear) || null,
                    liber,
                    page
                }
            );
            dispatch(fetchExamOrderParentSuccessorsDocumentGroupData(orderId));
            dispatch(addDocumentReferences({ docId: documentId, references: newReferences }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Add document reference: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Remove a document reference from an existing document
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document to remove a reference from
 * @param {string} documentReferenceId ID of the reference to remove
 * @returns {AppThunk}
 */
export const removeOrderDocumentReferenceThunk =
    (orderId: string, documentId: string, documentReferenceId: string): AppThunk =>
    async (dispatch) => {
        try {
            await api.examOrderReferences.deleteExamOrderDocumentReference(
                orderId,
                documentId,
                documentReferenceId
            );
            dispatch(fetchExamOrderParentSuccessorsDocumentGroupData(orderId));
            dispatch(
                deleteDocumentReference({ docId: documentId, refId: documentReferenceId })
            );
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Remove document reference: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update the instrument identifiers object for an existing document
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document to update
 * @param {UpdateKeyDocumentInstrument} instrumentObject Object with the updated instrument identifiers
 * @returns {AppThunk}
 */
export const updateInstrumentComplexInputThunk =
    (
        orderId: string,
        documentId: string,
        instrumentObject: UpdateKeyDocumentInstrument
    ): AppThunk =>
    async (dispatch) => {
        try {
            const response =
                await api.examOrderDocumentFields.updateDocumentInstrumentWithParagraphsApi(
                    orderId,
                    documentId,
                    instrumentObject
                );
            dispatch(
                updateInstrumentComplexInput({ docId: documentId, newValue: instrumentObject })
            );
            if (response.paragraphs.length > 0) {
                response.paragraphs.forEach((para) => {
                    dispatch(updateParagraph({ id: para.id, paragraph: para }));
                });
            }
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Update instrument complex input: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Add an array of legal codes to an existing document
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document to add codes to
 * @param {number[]} codesArray Array of code IDs to add to the document
 * @returns {AppThunk}
 */
export const addOrderDocumentCodeThunk =
    (orderId: string, documentId: string, codesArray: number[]): AppThunk =>
    async (dispatch) => {
        try {
            const addedCodes = await api.examOrderDocumentFields.addDocumentCodes(
                orderId,
                documentId,
                codesArray
            );
            dispatch(addDocumentCodes({ docId: documentId, codes: addedCodes }));
            dispatch(getAllExceptionsAndRequirementsThunk(orderId));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Add Document Code: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * removes an legal code from an existing document
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document to add codes to
 * @param {number[]} codeId Code Id to be removed
 * @returns {AppThunk}
 */
export const removeOrderKeyDocumentCodeThunk =
    (orderId: string, documentId: string, codeId: number): AppThunk =>
    async (dispatch, getState) => {
        try {
            const { examOrderDocumentGroup } = getState().examOrderKeyDocumentGroupData;

            const targetGroupAr = examOrderDocumentGroup.filter((group) =>
                group.documents.map((doc) => doc.id).includes(documentId)
            );
            targetGroupAr.forEach(async (targetGroup) => {
                const targetKeyDocument = targetGroup.documents.find(
                    (document) => document.id === documentId
                );
                const targetCodeIndex = targetKeyDocument.codes.findIndex(
                    (code) => code.id === codeId
                );
                const codesArrayCopy = [...targetKeyDocument.codes];
                codesArrayCopy.splice(targetCodeIndex, 1);
                const updatedCodes = codesArrayCopy.reduce(
                    (acc: number[], currentValue) => [...acc, currentValue.id],
                    []
                );
                const addedCodes = await api.examOrderDocumentFields.setDocumentCodes(
                    orderId,
                    documentId,
                    updatedCodes
                );
                dispatch(addDocumentCodes({ docId: documentId, codes: addedCodes }));
                dispatch(getAllExceptionsAndRequirementsThunk(orderId));
            });
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Remove Document Code: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Add a new key document files to an order
 * @param {string} orderId ID of the order
 * @param {NewKeyDocumentForm} newKeyDocFormState Object that contains the form fields of the new document
 * @returns {CreatePulseDocumentFileDto[]}
 */
export const uploadNewKeyDocumentFiles = async (
    orderId: string,
    newKeyDocFormState: NewKeyDocumentForm
): Promise<CreatePulseDocumentFileDto[]> => {
    try {
        return await api.examBlobDocumentFile.uploadImage(orderId, newKeyDocFormState.files);
    } catch (err) {
        throw new FileUploadError(err.message);
    }
};

/**
 * update a existing key document to an order
 * @param {string} orderId ID of the order
 * @param {KeyDocument} document key document data of row
 * @param {NewKeyDocumentForm} newKeyDocFormState Object that contains the form fields of the new document
 * @param {string} propertyId ID of the property the document will be linked to
 * @param {string} categoryId ID of the category of the new document
 * @param {string} subCategoryId ID of the subcategory of the new document
 * @param {Function} handleCancel Clear and close the form
 * @returns {AppThunk}
 */
export const updateExistingKeyDocumentThunk =
    (
        orderId: string,
        document: KeyDocument,
        newKeyDocFormState: NewKeyDocumentForm,
        propertyId: string,
        categoryId: string,
        subCategoryId: string,
        handleCancel: () => void
    ): AppThunk =>
    async (dispatch) => {
        try {
            await api.examUploadDocument.updateExistingRecordedDocument(
                newKeyDocFormState,
                orderId,
                document,
                propertyId,
                categoryId,
                subCategoryId
            );
            dispatch(fetchExamOrderParentSuccessorsDocumentGroupData(orderId));
            handleCancel();
        } catch (err) {
            if (err instanceof FileUploadError) {
                dispatch(
                    setFileUploadError({
                        error: true,
                        message: err.message
                    })
                );
            } else {
                dispatch(
                    setSnackbarState({
                        open: true,
                        message: `Add Key Document: ${err.message}`,
                        severity: SnackbarSeverity.Error
                    })
                );
            }
        }
    };

/**
 * Add a new key document to an order
 * @param {string} orderId ID of the order
 * @param {NewKeyDocumentForm} newKeyDocFormState Object that contains the form fields of the new document
 * @param {string} propertyId ID of the property the document will be linked to
 * @param {string} categoryId ID of the category of the new document
 * @param {string} subCategoryId ID of the subcategory of the new document
 * @param {Function} handleCancel Clear and close the form
 * @returns {AppThunk}
 */
export const addNewKeyDocumentThunk =
    (
        orderId: string,
        newKeyDocFormState: NewKeyDocumentForm,
        propertyId: string,
        categoryId: string,
        subCategoryId: string,
        handleCancel: () => void
    ): AppThunk =>
    async (dispatch) => {
        try {
            const uploadedImage = await uploadNewKeyDocumentFiles(orderId, newKeyDocFormState);
            await api.examUploadDocument.uploadRecordedDocumentWithNoImage(
                newKeyDocFormState,
                uploadedImage,
                orderId,
                propertyId,
                categoryId,
                subCategoryId
            );
            // dispatch(fetchExamOrderDocumentGroupData(orderId));
            dispatch(fetchExamOrderParentSuccessorsDocumentGroupData(orderId));
            dispatch(getAllExceptionsAndRequirementsThunk(orderId));
            handleCancel();
        } catch (err) {
            if (err instanceof FileUploadError) {
                dispatch(
                    setFileUploadError({
                        error: true,
                        message: err.message
                    })
                );
            } else {
                dispatch(
                    setSnackbarState({
                        open: true,
                        message: `Add Key Document: ${err.message}`,
                        severity: SnackbarSeverity.Error
                    })
                );
            }
        }
    };

/**
 * Change the tagged state of all documents in a document group
 * @param {string} orderId ID of the order
 * @param {string[]} documentIds IDs of the documents to update
 * @param {boolean} isTagged Tagged value to assign to the documents
 * @param {string} groupId ID of the group containing the documents
 * @returns {AppThunk}
 */
export const setAllDocumentsTagUntagThunk =
    (orderId: string, documentIds: string[], isTagged: boolean, groupId: string): AppThunk =>
    async (dispatch) => {
        try {
            const {
                isTagged: isTaggedResponse,
                affectedVestingIds,
                affectedLegalDescriptionIds,
                isReviewStatedChanged
            } = await api.documentTagUntag.apiSetAllDocsIsTagged(
                orderId,
                documentIds,
                isTagged
            );
            if (isReviewStatedChanged) dispatch(fetchExamOrderReviewStateThunk(orderId));
            if (affectedVestingIds.length) dispatch(fetchExamOrderVestingData(orderId));
            if (affectedLegalDescriptionIds.length)
                dispatch(fetchExamOrderLegalDescriptionData(orderId));
            dispatch(getAllExceptionsAndRequirementsThunk(orderId));
            dispatch(setAllDocsIsTagged({ groupId: groupId, isTagged: isTaggedResponse }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Set all documents in group tag or untag: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Change the tagged state of child and grand child document of a parent document
 * @param {string} orderId ID of the order
 * @param {string[]} documentIds IDs of the documents to update
 * @param {boolean} isTagged Tagged value to assign to the documents
 * @param {string} groupId ID of the group containing the documents
 * @returns {AppThunk}
 */
export const setChildDocumentsTagUntagThunk =
    (orderId: string, documentIds: string[], isTagged: boolean, groupId: string): AppThunk =>
    async (dispatch) => {
        try {
            const {
                isTagged: isTaggedResponse,
                affectedVestingIds,
                affectedLegalDescriptionIds,
                isReviewStatedChanged
            } = await api.documentTagUntag.apiSetAllDocsIsTagged(
                orderId,
                documentIds,
                isTagged
            );
            if (isReviewStatedChanged) dispatch(fetchExamOrderReviewStateThunk(orderId));
            if (affectedVestingIds.length) dispatch(fetchExamOrderVestingData(orderId));
            if (affectedLegalDescriptionIds.length)
                dispatch(fetchExamOrderLegalDescriptionData(orderId));

            dispatch(
                setChildDocsIsUnTagged({
                    groupId: groupId,
                    documentIds,
                    isTagged: isTaggedResponse
                })
            );
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Set child and grandchild documents of a parent document tag or untag: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };
/**
 * Change the tagged state of a single document in a document group
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document to update
 * @param {boolean} isTagged Tagged value to assign to the document
 * @returns {AppThunk}
 */
export const setDocumentTagUntagThunk =
    (orderId: string, documentId: string, isTagged: boolean): AppThunk =>
    async (dispatch) => {
        try {
            const {
                isTagged: isTaggedResponse,
                documentId: documentIdResponse,
                isReviewStatedChanged,
                affectedVestingIds,
                affectedLegalDescriptionIds
            } = await api.documentTagUntag.apiSetDocumentIsTagged(
                orderId,
                documentId,
                isTagged
            );
            if (isReviewStatedChanged) dispatch(fetchExamOrderReviewStateThunk(orderId));
            if (affectedVestingIds.length) dispatch(fetchExamOrderVestingData(orderId));
            if (affectedLegalDescriptionIds.length)
                dispatch(fetchExamOrderLegalDescriptionData(orderId));
            dispatch(getAllExceptionsAndRequirementsThunk(orderId));
            dispatch(
                setIsDocumentTagged({ docId: documentIdResponse, isTagged: isTaggedResponse })
            );
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Set document tag or untag: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Upload a file and attach to an existing document
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document to attach the file to
 * @param {File} file File to attach to the document
 * @returns {AppThunk}
 */
export const uploadDocumentImageThunk =
    (orderId: string, documentId: string, file: File): AppThunk =>
    async (dispatch) => {
        try {
            const result = await api.examBlobDocumentFile.postExamDocumentFile(
                orderId,
                documentId,
                file
            );
            dispatch(updateDocumentFile({ docId: documentId, file: result }));
            dispatch(
                setHasPulseFilesFlagOfVestingData({ docId: documentId, hasPulseFiles: true })
            );
            dispatch(
                setHasPulseFilesFlagOfLegalData({ docId: documentId, hasPulseFiles: true })
            );
        } catch (err) {
            dispatch(
                setFileUploadError({
                    error: true,
                    message: err.message
                })
            );
        }
    };

/**
 * Add a new note to an existing document
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document to add a note to
 * @param {string} documentNote String containing the document note
 * @returns {AppThunk}
 */
export const addNewNoteThunk =
    (orderId: string, documentId: string, documentNote: string): AppThunk =>
    async (dispatch) => {
        try {
            const result = await api.examOrderDocumentNotes.addExamOrderDocumentNote(
                orderId,
                documentId,
                documentNote
            );
            dispatch(updateDocumentNote({ docId: documentId, documentNote: result }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Add new document note: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update a note in an existing document
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document that owns the note
 * @param {DocumentNote} documentNote Updated note object
 * @returns {AppThunk}
 */
export const updateNoteThunk =
    (orderId: string, documentId: string, documentNote: DocumentNote): AppThunk =>
    async (dispatch) => {
        try {
            const result = await api.examOrderDocumentNotes.updateExamOrderDocumentNote(
                orderId,
                documentId,
                documentNote
            );
            dispatch(updateDocumentNote({ docId: documentId, documentNote: result }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Add/update new document note: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Remove a note from an existing document
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document that owns the note
 * @param {string} noteId ID of the note to remove
 * @returns {AppThunk}
 */
export const removeNoteThunk =
    (orderId: string, documentId: string, noteId: string): AppThunk =>
    async (dispatch) => {
        try {
            await api.examOrderDocumentNotes.deleteExamOrderDocumentNoteById(
                orderId,
                documentId,
                noteId
            );
            dispatch(removeDocumentNote({ docId: documentId, noteId }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Remove document note: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update the amount field of a document
 * @param {string} orderId ID of the order
 * @param {string} docId ID of the document to update
 * @param {number} amount Updated amount
 * @returns {AppThunk}
 */
export const updateAmountThunk =
    (orderId: string, docId: string, amount: number): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.examOrderDocumentFields.updateAmountFieldWithParagraphs(
                orderId,
                docId,
                amount
            );
            dispatch(updateAmount({ docId, amount }));
            if (response.paragraphs.length > 0) {
                response.paragraphs.forEach((para) => {
                    dispatch(updateParagraph({ id: para.id, paragraph: para }));
                });
            }
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Update amount: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update the transfer tax amount field of a document
 * @param {string} orderId ID of the order
 * @param {string} docId ID of the document to update
 * @param {number} amount Updated amount
 * @returns {AppThunk}
 */
export const updateTransferTaxAmountThunk =
    (orderId: string, docId: string, amount: number): AppThunk =>
    async (dispatch) => {
        try {
            const response =
                await api.examOrderDocumentFields.updateTransferTaxAmountFieldWithParagraphs(
                    orderId,
                    docId,
                    amount
                );
            dispatch(updateTransferTaxAmount({ docId, amount }));
            if (response.paragraphs.length > 0) {
                response.paragraphs.forEach((para) => {
                    dispatch(updateParagraph({ id: para.id, paragraph: para }));
                });
            }
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Update transfer tax amount: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Set category and subcategory
 * @param {DocumentCategorySubCategoryDto}
 * @returns {AppThunk}
 */
export const setSubCategoryThunk =
    ({
        orderId,
        documentId,
        documentCategoryId,
        documentSubCategoryId,
        sourceDocumentTypeName
    }: DocumentCategorySubCategoryDto): AppThunk =>
    async (dispatch) => {
        try {
            const updatedDocTypeData =
                await api.examOrderDocumentFields.setCategoryAndSubcategoryApi({
                    orderId,
                    documentId,
                    documentCategoryId,
                    documentSubCategoryId,
                    sourceDocumentTypeName
                });
            if (updatedDocTypeData) {
                dispatch(
                    updateCategorySubCategoryOfDoc({
                        docId: documentId,
                        docTypeResponse: updatedDocTypeData
                    })
                );
            }
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Set document and subdocument: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

export default examOrderKeyDocumentGroupSlice.reducer;
