import api from 'core/api/api';
import { FinalReviewDto } from 'types/dto/finalReviewDto';
import { PulseWarning } from 'types/searchReport';

/**
 * Get final review data
 * @function
 * @category API
 * @param {string} orderId - The order ID of the order you want to get the final review data for.
 * @returns {FinalReviewDto}
 */
export const getFinalReviewDataApi = async (orderId: string): Promise<FinalReviewDto> => {
    try {
        const response = await api.get<FinalReviewDto>(
            `/api/examOrder/${orderId}/finalReview`
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};

/**
 * Get the warnings for a given order
 * @function
 * @category API
 * @param {string} orderId - Order id of the order you want to get the warnings data for
 * @returns {PulseWarning[]}
 */
export const getFinalReviewWarningsApi = async (orderId: string): Promise<PulseWarning[]> => {
    try {
        const response = await api.get<PulseWarning[]>(
            `/api/examOrder/${orderId}/finalReview/warning`
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};

/**
 * It solves a warning on a final review
 * @function
 * @category API
 * @param {string} orderId - The id of the order
 * @param {string} warningId - the id of the warning to be solved
 * @returns {PulseWarning[]}
 */
export const solveFinalReviewWarningApi = async (
    orderId: string,
    warningId: string
): Promise<PulseWarning[]> => {
    try {
        const response = await api.put<null, PulseWarning[]>(
            `/api/examOrder/${orderId}/finalReview/warning/${warningId}/solve`
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};
