import api from 'core/api/api';
import { PagedOrdersApiParams } from 'types/propTypes';
import { OriginalOrder, PagedData, ProjectOrder, ProjectStatus } from 'types/dataModels';

enum PagedApiParamsNames {
    page = 'page',
    pageSize = 'pageSize',
    search = 'search',
    orderIds = 'orderIds',
    countyIds = 'countyIds',
    stateIds = 'stateIds',
    businessSegmentIds = 'businessSegmentIds',
    productTypeIds = 'productTypeIds',
    clientIds = 'clientIds',
    statusId = 'statusIds',
    sorting = 'sorting',
    fromDate = 'fromDate',
    toDate = 'toDate'
}

/**
 * Get paged list of orders
 * @function
 * @category API
 * @param {number} page
 * @param {number} pageSize
 * @param {string} search
 * @param {number[]} stateIds
 * @param {number[]} countyIds
 * @param {number[]} productTypeIds
 * @param {number[]} businessSegmentIds
 * @param {number[]} clientIds
 * @param {string[]} statusIds
 * @param {Object} sorting
 * @param {string} fromDate
 * @param {string} toDate
 * @returns {PagedData<ProjectOrder>}
 */
export const getPagedOrdersDataApi = async ({
    page,
    pageSize,
    search,
    stateIds,
    countyIds,
    productTypeIds,
    businessSegmentIds,
    clientIds,
    statusIds,
    sorting,
    fromDate,
    toDate
}: PagedOrdersApiParams): Promise<PagedData<ProjectOrder>> => {
    const requestData: PagedOrdersApiParams = {
        page,
        pageSize,
        search: search === '' ? null : search,
        stateIds: stateIds.length ? stateIds : null,
        countyIds: countyIds.length ? countyIds : null,
        productTypeIds: productTypeIds.length ? productTypeIds : null,
        businessSegmentIds: businessSegmentIds.length ? businessSegmentIds : null,
        clientIds: clientIds.length ? clientIds : null,
        statusIds: statusIds.length ? statusIds : null,
        sorting,
        fromDate: fromDate === '' ? null : fromDate,
        toDate: toDate === '' ? null : toDate
    };

    // Remove unused fields
    const filteredRequestData: Omit<PagedOrdersApiParams, 'page' | 'pageSize'> = Object.keys(
        requestData
    )
        .filter((k) => requestData[k as PagedApiParamsNames] != null)
        .reduce((a, k) => ({ ...a, [k]: requestData[k as PagedApiParamsNames] }), {});

    // Temporary solution, sorting reduce performance
    // After changes from backend need to delete line below
    delete filteredRequestData.sorting;
    try {
        const response = await api.getPaged<PagedData<ProjectOrder>>({
            ...filteredRequestData,
            pageSize,
            pageNumber: page,
            url: `/api/projectOrder/paged`
        });
        return response.data;
    } catch (err) {
        throw new Error(err.message);
    }
};

/**
 * Create a new order
 * @function
 * @category API
 * @param {Omit<OriginalOrder, 'id'>} data
 */
export const postOrdersDataApi = async (data: Omit<OriginalOrder, 'id'>): Promise<void> => {
    try {
        await api.post<Omit<OriginalOrder, 'id'>, string>(`/api/testorder/resware`, data);
    } catch (err) {
        throw new Error(err.message);
    }
};

/**
 * Get an order by id value
 * @function
 * @category API
 * @param {string} id - target order id
 * @returns {ProjectOrder}
 */
export const getOrderById = async (id: string): Promise<ProjectOrder> => {
    try {
        const response = await api.get<ProjectOrder>(`/api/orderProject/${id}`);
        return response.data;
    } catch (err) {
        throw new Error(err.message);
    }
};

/**
 * Get list of allowed statuses for order
 * @function
 * @category API
 * @param {string} orderId - Target order id value
 * @returns {ProjectStatus[]}
 */
export const getAllowedStatuses = async (orderId: string): Promise<ProjectStatus[]> => {
    try {
        const response = await api.get<ProjectStatus[]>(
            `/api/orderProject/${orderId}/status/allowed`
        );
        return response.data;
    } catch (err) {
        throw new Error(err.message);
    }
};

/**
 * Update current order status
 * @function
 * @category API
 * @param {string} orderId - Current order id
 * @param {string} statusId - New status id
 * @param {string} notes - Pause reason
 * @returns {ProjectOrder}
 */
export const updateOrderStatus = async (
    orderId: string,
    statusId: string,
    notes?: string
): Promise<ProjectOrder> => {
    try {
        const response = await api.put<
            { projectStatusId: string; notes: string },
            ProjectOrder
        >(`/api/orderProject/${orderId}/status/set`, {
            projectStatusId: statusId,
            notes: notes ?? 'Default note'
        });
        return response.data;
    } catch (err) {
        throw new Error(err.message);
    }
};

/**
 * Start new exam
 * @function
 * @category API
 * @param {string} projectId - Target order id
 * @returns {ProjectOrder}
 */
export const startExam = async (projectId: string): Promise<ProjectOrder> => {
    try {
        const response = await api.put<null, ProjectOrder>(
            `/api/projectOrder/${projectId}/status/startExam`
        );
        return response.data;
    } catch (err) {
        throw new Error(err.message);
    }
};
