import api from 'core/api/api';
import { ValueDocLink, VestingValue } from 'types/dataModels';
import { VestingResultDto } from 'types/dto/vesting';

/**
 * Get order vesting by orderId
 * @function
 * @category API
 * @param {string} orderId - Current order id value
 * @returns {VestingValue[]}
 */
export const getExamOrderVesting = async (orderId: string): Promise<VestingValue[]> => {
    try {
        const response = await api.get<VestingValue[]>(`/api/examOrder/${orderId}/vesting`);
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};

/**
 * Submit editor value as vesting value for current order
 * @function
 * @category API
 * @param {string} orderId - Current order id value
 * @param {string} examValue - Vesting value
 * @param {ValueDocLink} examValueDocLinks - array of document links within the vesting editor
 */
export const saveFinalOrderVesting = async (
    orderId: string,
    examValue: string,
    examValueDocLinks: ValueDocLink[]
) => {
    try {
        const response = await api.put<
            {
                examValue: string;
                examValueDocLinks: ValueDocLink[];
            },
            VestingResultDto
        >(`/api/examOrder/${orderId}/vesting/exam`, {
            examValue: examValue,
            examValueDocLinks: examValueDocLinks
        });
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};

/**
 * Submit current vesting instrument as vesting value for order
 * @function
 * @category API
 * @param {string} orderId - Current order id value
 * @param {string} vestingId - Target vesting instrument id value
 */
export const applyInstrumentAsOrderVesting = async (orderId: string, vestingId: string) => {
    try {
        const response = await api.put<null, VestingResultDto>(
            `/api/examOrder/${orderId}/vesting/${vestingId}/apply`
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};
