import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { IconPaths, Icon } from 'components/styled/Icon';
import colors from 'core/constants/colors';
import { ButtonCaseProps } from 'types/propTypes';
import { ButtonVariants } from 'core/constants/common';
import React from 'react';

const PrimaryButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'retainCase'
})<ButtonCaseProps>(({ retainCase }) => ({
    backgroundColor: '#483D91',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '16px',
    height: '32px',
    minWidth: 'max-content',
    padding: '8px 12px',
    border: '1.5px solid #483D91',
    borderRadius: 0,
    textTransform: retainCase ? 'none' : 'uppercase',

    fontFamily: ['Montserrat', 'sans-serif'].join(',')
}));

const SecondaryButton = styled(PrimaryButton)({
    backgroundColor: colors.main.primaryLight,
    border: '1.5px solid #483D91',
    color: '#483D91',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '16px',
    height: '32px',
    minWidth: 'max-content',
    padding: '8px 12px',
    borderRadius: 0,
    '&:hover': {
        backgroundColor: colors.main.accentHighlight15
    },

    fontFamily: ['Montserrat', 'sans-serif'].join(',')
});

const SuccessButton = styled(PrimaryButton)({
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    border: '0px',
    color: colors.additional.success_win,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '16px',
    height: '32px',
    minWidth: 'max-content',
    padding: '8px 12px',
    borderRadius: 0,

    fontFamily: ['Montserrat', 'sans-serif'].join(',')
});

export interface IButtonProps {
    id?: string;
    text: string;
    action: (event?: React.MouseEvent) => void;
    disabled?: boolean;
    variant?: ButtonVariants;
    retainCase?: boolean;
    endIcon?: JSX.Element;
}

/**
 * Button component
 * @param {string} id Id
 * @param {string} text Button text
 * @param {Function} action Click action handler
 * @param {boolean} disabled Is disabled
 * @param {ButtonVariants} variant Button variant
 * @param {boolean} retainCase Is use retain case
 * @param {JSX.Element} endIcon Icon for button if necessary
 * @component
 * @returns {JSX.Element}
 */
const MainButton = ({
    id,
    text,
    action,
    disabled,
    variant,
    retainCase,
    endIcon
}: IButtonProps) => {
    if (variant === ButtonVariants.primary || !variant) {
        return (
            <PrimaryButton
                id={id}
                retainCase={retainCase}
                onClick={action}
                variant="contained"
                size="large"
                disabled={disabled}
                endIcon={endIcon}
                style={{ borderRadius: 0 }}>
                {text}
            </PrimaryButton>
        );
    }
    if (variant === ButtonVariants.secondary) {
        return (
            <SecondaryButton
                id={id}
                retainCase={retainCase}
                onClick={action}
                variant="contained"
                size="large"
                disabled={disabled}
                endIcon={endIcon}
                style={{ borderRadius: 0 }}>
                {text}
            </SecondaryButton>
        );
    }
    if (variant === ButtonVariants.success) {
        return (
            <SuccessButton
                retainCase={retainCase}
                onClick={action}
                variant="contained"
                size="large"
                disabled={disabled}
                style={{ borderRadius: 0 }}
                endIcon={
                    <Icon
                        width={12}
                        height={12}
                        path={IconPaths.done}
                        fill={colors.additional.success_win}
                    />
                }>
                {text}
            </SuccessButton>
        );
    }
};

export default MainButton;
