import { useState, useMemo, useEffect, useRef } from 'react';
import {
    InteractionRequiredAuthError,
    IPublicClientApplication,
    RedirectRequest,
    SilentRequest
} from '@azure/msal-browser';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { MSAL_CONFIG } from 'core/constants/common';
import qs from 'qs';
import { useMsal } from '@azure/msal-react';
import { AxiosCacheInstance, buildWebStorage, setupCache } from 'axios-cache-interceptor';

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
    reqIdx: number;
}

const msalConfig = JSON.parse(localStorage.getItem(MSAL_CONFIG));

const getToken = async (msalInstance: IPublicClientApplication): Promise<string> => {
    const accounts = msalInstance.getAllAccounts();
    const silentRequest: SilentRequest = {
        scopes: msalConfig.scopes,
        account: accounts[0],
        forceRefresh: false
    };
    const redirectRequest: RedirectRequest = {
        scopes: msalConfig.scopes
    };
    try {
        const response = await msalInstance.acquireTokenSilent(silentRequest);
        return response.accessToken;
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            await msalInstance.acquireTokenRedirect(redirectRequest);
        }
    }
};

const axiosInstance = axios.create({
    baseURL: msalConfig?.apiUrl || '',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
}) as AxiosCacheInstance;

setupCache(axiosInstance, { storage: buildWebStorage(localStorage, 'axios-cache:') });

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (!error.response) {
            return Promise.reject(error);
        }
        return Promise.reject(error);
    }
);

export const useAxiosLoader = () => {
    const { instance } = useMsal();
    const [isLoading, setIsLoading] = useState(true);
    const mountedRef = useRef(false);

    let requestsCount: CustomAxiosRequestConfig[] = [];
    let requestsIndex = 0;

    const addRequest = (config: CustomAxiosRequestConfig) => {
        if (!mountedRef.current) return;
        setIsLoading(true);
        requestsIndex = requestsIndex + 1;
        config['reqIdx'] = requestsIndex;
        requestsCount.push(config);
    };

    const removeRequest = (config: CustomAxiosRequestConfig) => {
        if (!mountedRef.current) return;
        requestsCount = requestsCount.filter((arr) => arr.reqIdx !== config.reqIdx);
        if (!requestsCount.length) setIsLoading(false);
    };

    const interceptors = useMemo(
        () => ({
            request: async (config: CustomAxiosRequestConfig) => {
                const token: string = await getToken(instance);
                config.headers.authorization = `Bearer ${token}`;
                addRequest(config);
                return config;
            },
            response: (response: AxiosResponse): AxiosResponse => {
                removeRequest(response.config as CustomAxiosRequestConfig);
                return response;
            },
            error: (error: AxiosError) => {
                removeRequest(error.response.config as CustomAxiosRequestConfig);
                return Promise.reject(error);
            }
        }),
        []
    );

    useEffect(() => {
        mountedRef.current = true;
        const reqInterceptor = axiosInstance.interceptors.request.use(
            interceptors.request,
            interceptors.error
        );
        const resInterceptor = axiosInstance.interceptors.response.use(
            interceptors.response,
            interceptors.error
        );
        return () => {
            mountedRef.current = false;
            axiosInstance.interceptors.request.eject(reqInterceptor);
            axiosInstance.interceptors.response.eject(resInterceptor);
        };
    }, []);

    return [isLoading];
};

export default {
    get: <T>(url: string): Promise<AxiosResponse<T>> =>
        axiosInstance.get<T>(url, { cache: false }),

    getWithCache: <T>(url: string): Promise<AxiosResponse<T>> =>
        axiosInstance.get<T>(url, { cache: { ttl: Number.MAX_SAFE_INTEGER } }),

    getPdfFile: <T>(url: string): Promise<AxiosResponse<T>> =>
        axiosInstance(url, {
            cache: false,
            method: 'GET',
            responseType: 'arraybuffer',
            headers: {
                accept: 'text/plain'
            }
        }),

    getPaged: <T>(args: {
        url: string;
        pageNumber: number;
        pageSize: number;
        search?: string;
        orderIds?: string[];
        countyIds?: number[];
        stateIds?: number[];
        businessSegmentIds?: number[];
        productTypeIds?: number[];
        clientIds?: number[];
        statusIds?: string[];
        sorting?: {
            fieldSorting: { name: string; direction: 0 | 1 }[];
        };
        fromDate?: string;
        toDate?: string;
        params?: AxiosRequestConfig;
    }): Promise<AxiosResponse<T>> =>
        axiosInstance.get<T>(args.url, {
            cache: false,
            params: {
                Page: args.pageNumber,
                PageSize: args.pageSize,
                Search: args.search,
                OrderIds: args.orderIds,
                CountyIds: args.countyIds,
                StateIds: args.stateIds,
                BusinessSegmentIds: args.businessSegmentIds,
                ProductTypeIds: args.productTypeIds,
                ClientIds: args.clientIds,
                StatusIds: args.statusIds,
                Sorting: args.sorting,
                fromDate: args.fromDate,
                toDate: args.toDate
            },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
        }),

    post: <D, R>(
        url: string,
        data?: D,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<R>> => axiosInstance.post<R>(url, data, config),

    put: <D, R>(
        url: string,
        data?: D,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<R>> => axiosInstance.put<R>(url, data, config),

    delete: (url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> =>
        axiosInstance.delete(url, { data: config }),

    patch: <T>(url: string, data?: T, config?: AxiosRequestConfig): Promise<AxiosResponse> =>
        axiosInstance.patch(url, data, config)
};
