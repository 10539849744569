import {
    AddDocumentReferenceModel,
    DocumentReference,
    ExamOrderReferencedDocument
} from 'types/dataModels';
import api from '../api';

/**
 * Get all document references
 * @function
 * @category API
 * @param {string} orderId - The id of the order
 * @param {string} instrumentNumber - Document instrument number,
 * @param {number} instrumentYear - Document instrument year
 * @param {string} liber - Document liber
 * @param {string} page - Document page
 * @returns {ExamOrderReferencedDocument[]}
 */
export const getExamOrderReferencedDocument = async (
    orderId: string,
    instrumentNumber: string,
    instrumentYear: number,
    liber: string,
    page: string
): Promise<ExamOrderReferencedDocument[]> => {
    const correctYear = Number(instrumentYear) ? instrumentYear.toString() : '';
    const queryString = new URLSearchParams({
        instrumentNumber: instrumentNumber,
        instrumentYear: correctYear,
        liber: liber,
        page: page
    });
    try {
        const response = await api.get<ExamOrderReferencedDocument[]>(
            `/api/examOrder/${orderId}/document?${queryString}`
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};

/**
 * It gets the document reference for a given exam order and document
 * @function
 * @category API
 * @param {string} orderId - The id of the order
 * @param {string} documentId - The id of the document you want to get the reference for.
 * @returns {DocumentReference[]}
 */
export const getExamOrderDocumentReference = async (orderId: string, documentId: string) => {
    try {
        const response = await api.get<DocumentReference[]>(
            `/api/examOrder/${orderId}/document/${documentId}/reference`
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};

/**
 * It adds a document reference to an exam order document
 * @function
 * @category API
 * @param {string} orderId - The id of the order you want to add the document reference to
 * @param {string} documentId - The id of the document you want to add a reference to.
 * @param {AddDocumentReferenceModel} addDocumentReferenceData - new reference object
 * @returns {DocumentReference[]}
 */
export const addExamOrderDocumentReference = async (
    orderId: string,
    documentId: string,
    addDocumentReferenceData: AddDocumentReferenceModel
) => {
    try {
        const response = await api.post<AddDocumentReferenceModel, DocumentReference[]>(
            `/api/examOrder/${orderId}/document/${documentId}/reference`,
            addDocumentReferenceData
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};

/**
 * It deletes a document reference from an exam order document
 * @function
 * @category API
 * @param {string} orderId - The id of the order
 * @param {string} documentId - The id of the document you want to add the reference to.
 * @param {string} documentReferenceId - The id of the document reference to delete
 */
export const deleteExamOrderDocumentReference = async (
    orderId: string,
    documentId: string,
    documentReferenceId: string
) => {
    try {
        const response = await api.delete(
            `/api/examOrder/${orderId}/document/${documentId}/reference?documentReferenceId=${documentReferenceId}`
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};
