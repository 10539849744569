import api from 'core/api/api';
import {
    DocumentsTagUntagRequest,
    DocumentsTagUntagResponse,
    DocumentTagUntagRequest,
    DocumentTagUntagResponse
} from 'types/dto/documentTagUntag';

/**
 * This function makes a PUT request to the API to tag or untag a document.
 * @function
 * @category API
 * @subcategory documentTag/Untag
 * @param {string} orderId - string - The order id of the exam order
 * @param {string} docId - The document id
 * @param {boolean} isTagged - tag/untag value
 * @returns {DocumentTagUntagResponse} DocumentTagUntagResponse
 */
export const apiSetDocumentIsTagged = async (
    orderId: string,
    docId: string,
    isTagged: boolean
): Promise<DocumentTagUntagResponse> => {
    try {
        const response = await api.put<DocumentTagUntagRequest, DocumentTagUntagResponse>(
            `/api/examOrder/${orderId}/document/${docId}/tagUnTag`,
            {
                isTagged: isTagged
            }
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};

/**
 * This function will tag or untag selected group of documents.
 * @function
 * @category API
 * @subcategory documentTag/Untag
 * @param {string} orderId - string - The order id of the exam order
 * @param {string[]} documentIds - An array of document IDs to tag or untag.
 * @param {boolean} isTagged - boolean - true if you want to tag the documents, false if you want to
 * untag them
 * @returns {DocumentsTagUntagResponse} DocumentsTagUntagResponse
 */
export const apiSetAllDocsIsTagged = async (
    orderId: string,
    documentIds: string[],
    isTagged: boolean
): Promise<DocumentsTagUntagResponse> => {
    try {
        const response = await api.put<DocumentsTagUntagRequest, DocumentsTagUntagResponse>(
            `/api/examOrder/${orderId}/document/tagUnTag`,
            {
                documentIds,
                isTagged
            }
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};
