import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'core/store/store';
import api from 'core/api';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { DEFAULT_GUID, OrderStatusesIds, SnackbarSeverity } from 'core/constants/common';
import {
    ExamOrder,
    ExamProperty,
    OrderLegalEntity,
    PropertyIdentifier,
    PropertyLegalDescription,
    UpdatePropertyAddressResponse
} from 'types/examOrderDataModel';
import { Address, ProjectOrder, ProjectStatus } from 'types/dataModels';
import {
    fetchAllowedStatuses,
    setIsOnHoldViewVisible,
    setReadOnlyView,
    setWorkbenchPauseReason,
    setWorkbenchStatus
} from '../workbenchStatus/workbenchStatusSlice';

interface CurrentExamOrderState {
    projectOrder: ProjectOrder;
    currentExamOrder: ExamOrder | null;
}
const duplicateErrorMsg = 'Name not accepted, duplicate entry.';
const initialState: CurrentExamOrderState = {
    projectOrder: {} as ProjectOrder,
    currentExamOrder: null
};

const currentExamOrderSlice = createSlice({
    name: 'currentExamOrder',
    initialState,
    reducers: {
        /**
         * Set current exam order data to store
         * @param state Slice state
         * @param action Payload with the data of the current order
         */
        setCurrentExamOrder(state, action: PayloadAction<ExamOrder>) {
            state.currentExamOrder = action.payload;
        },
        /**
         * Add a legal entity to the current order
         * @param state Slice state
         * @param action Payload with the legal entity to add to the order
         */
        addLegalEntity(state, action: PayloadAction<OrderLegalEntity>) {
            state.currentExamOrder.parties.push(action.payload);
        },
        /**
         * Remove a legal entity from the current order
         * @param state Slice state
         * @param action Payload with the ID of the legal entity to remove
         */
        removeLegalEntity(state, action: PayloadAction<OrderLegalEntity>) {
            const partyData = action.payload;
            let partyIndex: number;
            if (partyData.id) {
                partyIndex = state.currentExamOrder.parties.findIndex(
                    (party) => party.id === partyData.id
                );
            } else {
                partyIndex = state.currentExamOrder.parties.findIndex(
                    (party) => party.tempID === partyData.tempID
                );
            }

            state.currentExamOrder.parties.splice(partyIndex, 1);
        },
        /**
         * Update a legal entity in the current order
         * @param state Slice state
         * @param action Payload with the updated legal entity
         */
        updateLegalEntity(state, action: PayloadAction<OrderLegalEntity>) {
            const entity = action.payload;
            const targetIndex = state.currentExamOrder.parties.findIndex(
                (legalEntity) => legalEntity.id === entity.id
            );
            state.currentExamOrder.parties[targetIndex] = entity;
        },
        /**
         * Add a property to the current order
         * @param state Slice state
         * @param action Payload with the property to add
         */
        addNewProperty(state, action: PayloadAction<ExamProperty>) {
            const property = action.payload;
            state.currentExamOrder.properties.push(property);
        },
        /**
         * Update the address of a property in the current order
         * @param state Slice state
         * @param action Payload with the property ID and address changes
         */
        updatePropertyAddress(
            state,
            action: PayloadAction<{
                propertyId: string;
                addressChanges: UpdatePropertyAddressResponse;
            }>
        ) {
            const { propertyId, addressChanges } = action.payload;
            const { stateAbbrDashCountyName, commaCountyAddress, commaStreetAddress } =
                addressChanges;
            const targetProperty = state.currentExamOrder.properties.find(
                (property) => property.id === propertyId
            );
            targetProperty.address = addressChanges;
            targetProperty.commaCountyAddress = commaCountyAddress;
            targetProperty.commaStreetAddress = commaStreetAddress;
            targetProperty.stateAbbrDashCountyName = stateAbbrDashCountyName;
        },
        /**
         * Add a new tax parcel to an existing property in the current order
         * @param state Slice state
         * @param action Payload with the property ID and parcel data to add
         */
        addPropertyTaxParcel(
            state,
            action: PayloadAction<{ propertyId: string; parcel: PropertyIdentifier }>
        ) {
            const { propertyId, parcel } = action.payload;
            const targetProperty = state.currentExamOrder.properties.find(
                (property) => property.id === propertyId
            );
            targetProperty.parcelIds.push(parcel);
        },
        /**
         * Remove a tax parcel from an existing property in the current order
         * @param state Slice state
         * @param action Payload with the property ID and the parcel ID to remove from the property
         */
        removePropertyTaxParcel(
            state,
            action: PayloadAction<{ propertyId: string; parcelId: string }>
        ) {
            const { propertyId, parcelId } = action.payload;
            const targetProperty = state.currentExamOrder.properties.find(
                (property) => property.id === propertyId
            );
            const parcelIndex = targetProperty.parcelIds.findIndex(
                (parcel) => parcel.id === parcelId
            );
            targetProperty.parcelIds.splice(parcelIndex, 1);
        },
        /**
         * Update a tax parcel in an existing property in the current order
         * @param state Slice state
         * @param action Payload with the property ID, parcel ID and the updated parcel number
         */
        updatePropertyTaxParcel(
            state,
            action: PayloadAction<{ propertyId: string; parcelId: string; value: string }>
        ) {
            const { propertyId, parcelId, value } = action.payload;
            const targetProperty = state.currentExamOrder.properties.find(
                (property) => property.id === propertyId
            );
            const targetParcel = targetProperty.parcelIds.find(
                (parcel) => parcel.id === parcelId
            );
            targetParcel.value = value;
        },
        /**
         * Add a legal description to an existing property in the current order
         * @param state Slice state
         * @param action Payload with the property ID and legal description object to add
         */
        addPropertyLegalDescription(
            state,
            action: PayloadAction<{
                propertyId: string;
                legalDescription: PropertyLegalDescription;
            }>
        ) {
            const { propertyId, legalDescription } = action.payload;
            const targetProperty = state.currentExamOrder.properties.find(
                (property) => property.id === propertyId
            );
            targetProperty.legals.push(legalDescription);
        },
        /**
         * Update a legal description in an existing property in the current order
         * @param state Slice state
         * @param action Payload with the property ID, legal description ID and an object with the updated data
         */
        updatePropertyLegalDescription(
            state,
            action: PayloadAction<{
                propertyId: string;
                legalDescriptionId: string;
                legalDescriptionData: PropertyLegalDescription;
            }>
        ) {
            const { propertyId, legalDescriptionId, legalDescriptionData } = action.payload;
            const targetProperty = state.currentExamOrder.properties.find(
                (property) => property.id === propertyId
            );
            const legalDescriptionIndex = targetProperty.legals.findIndex(
                (legal) => legal.id === legalDescriptionId
            );
            targetProperty.legals[legalDescriptionIndex] = legalDescriptionData;
        },
        /**
         * Remove a legal description in an existing property in the current order
         * @param state Slice state
         * @param action Payload with the property ID and legal description ID to remove
         */
        removePropertyLegalDescription(
            state,
            action: PayloadAction<{ propertyId: string; legalDescriptionId: string }>
        ) {
            const { propertyId, legalDescriptionId } = action.payload;
            const targetProperty = state.currentExamOrder.properties.find(
                (property) => property.id === propertyId
            );
            const legalDescriptionIndex = targetProperty.legals.findIndex(
                (legal) => legal.id === legalDescriptionId
            );
            targetProperty.legals.splice(legalDescriptionIndex, 1);
        },
        /**
         * Set the current project order data
         * @param state Slice state
         * @param action Payload with the project order object to set
         */
        setCurrentProjectOrder(state, action: PayloadAction<ProjectOrder>) {
            state.projectOrder = action.payload;
        },
        /**
         * Set the status of the current project order
         * @param state Slice state
         * @param action Payload with the status object to assign to the order
         */
        setExamOrderStatus(state, action: PayloadAction<ProjectStatus>) {
            state.projectOrder.projectStatus = action.payload;
        },
        /**
         * Set the effective date of the current exam order
         * @param state Slice state
         * @param action Payload with the ISO date string representing the effective date of the order
         */
        setEffectiveDate(state, action: PayloadAction<string>) {
            state.currentExamOrder.effectiveDate = action.payload;
        }
    }
});
// @ts-ignore
const trimData = (partyData) => {
    Object.keys(partyData).forEach(
        (k) =>
            (partyData[k] =
                typeof partyData[k] == 'string' ? partyData[k].trim() : partyData[k])
    );
    // @ts-ignore
    return partyData;
};

const doesPartyExists = (
    partyList: OrderLegalEntity[],
    newParty: OrderLegalEntity,
    isNew: boolean
): boolean => {
    const partyFound: OrderLegalEntity = partyList.find((item) => {
        if (isNew) {
            return (
                (item.prefix?.toLowerCase() || null) ==
                    (newParty.prefix?.toLowerCase() || null) &&
                (item.first?.toLowerCase() || null) ==
                    (newParty.first?.toLowerCase() || null) &&
                (item.middle?.toLowerCase() || null) ==
                    (newParty.middle?.toLowerCase() || null) &&
                (item.last?.toLowerCase() || null) == (newParty.last?.toLowerCase() || null) &&
                (item.businessName?.toLowerCase() || null) ==
                    (newParty.businessName?.toLowerCase() || null) &&
                item.isBuyer == newParty.isBuyer &&
                item.isSeller == newParty.isSeller
            );
        } else {
            return (
                (item.prefix?.toLowerCase() || null) ==
                    (newParty.prefix?.toLowerCase() || null) &&
                (item.first?.toLowerCase() || null) ==
                    (newParty.first?.toLowerCase() || null) &&
                (item.middle?.toLowerCase() || null) ==
                    (newParty.middle?.toLowerCase() || null) &&
                (item.last?.toLowerCase() || null) == (newParty.last?.toLowerCase() || null) &&
                (item.businessName?.toLowerCase() || null) ==
                    (newParty.businessName?.toLowerCase() || null) &&
                item.isBuyer == newParty.isBuyer &&
                item.isSeller == newParty.isSeller &&
                item.id != newParty.id
            );
        }
    });
    return !(partyFound == null);
};

/**
 * Fetch exam data for a particular order
 * @param {string} orderId ID of the order
 * @returns {AppThunk}
 */
export const fetchExamOrder =
    (orderId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.examOrder.getExamOrder(orderId);
            dispatch(setCurrentExamOrder(response));
        } catch (err) {
            dispatch(setCurrentExamOrder(null));
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get exam order: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Add a new party entity to an order
 * @param {string} orderId ID of the order
 * @param {Omit<OrderLegalEntity, 'id' | 'isBusiness'>} partyData Party object to create a new entity
 * @returns {AppThunk}
 */
export const addOrderPartyThunk =
    (orderId: string, partyData: Omit<OrderLegalEntity, 'id' | 'isBusiness'>): AppThunk =>
    async (dispatch, getState) => {
        try {
            const { parties } = getState().currentExamOrderData.currentExamOrder;
            const newPartyData = trimData(partyData);
            if (
                doesPartyExists(
                    parties,
                    { ...newPartyData, id: '', isBusiness: !!newPartyData.businessName },
                    true
                )
            ) {
                dispatch(
                    setSnackbarState({
                        open: true,
                        autoHideDuration: 3000,
                        message: duplicateErrorMsg,
                        severity: SnackbarSeverity.Error
                    })
                );
                return;
            }
            if (
                newPartyData.businessName == '' &&
                newPartyData.first == '' &&
                newPartyData.last == '' &&
                newPartyData.middle == '' &&
                newPartyData.prefix == ''
            ) {
                dispatch(
                    addLegalEntity({
                        ...newPartyData,
                        id: '',
                        isBusiness: false,
                        tempID: (Math.floor(Math.random() * 90000) + 10000).toString()
                    })
                );
            } else {
                const response = await api.examOrderParties.addOrderPartyApi(
                    orderId,
                    newPartyData
                );
                dispatch(addLegalEntity(response));
            }
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Add order party: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Remove a party entity from an order
 * @param {string} orderId ID of the order
 * @param {OrderLegalEntity} partyData ID of the party to remove
 * @returns {AppThunk}
 */
export const removeOrderPartyThunk =
    (orderId: string, partyData: OrderLegalEntity): AppThunk =>
    async (dispatch) => {
        try {
            if (partyData.id) {
                await api.examOrderParties.removeOrderPartyApi(orderId, partyData.id);
            }
            dispatch(removeLegalEntity(partyData));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Remove order party: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update a party's fields
 * @param {string} orderId ID of the order
 * @param {OrderLegalEntity} party Updated party object
 * @returns {AppThunk}
 */
export const updateOrderPartyThunk =
    (orderId: string, party: OrderLegalEntity): AppThunk =>
    async (dispatch, getState) => {
        const newParty = trimData(party);

        if (newParty.id == '') {
            dispatch(removeOrderPartyThunk(orderId, newParty));
            dispatch(addOrderPartyThunk(orderId, newParty));
            return;
        }

        const { parties } = getState().currentExamOrderData.currentExamOrder;
        if (doesPartyExists(parties, newParty, false)) {
            dispatch(
                setSnackbarState({
                    open: true,
                    autoHideDuration: 3000,
                    message: duplicateErrorMsg,
                    severity: SnackbarSeverity.Error
                })
            );
            dispatch(removeOrderPartyThunk(orderId, newParty));
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { id: partyId, ...partyData } = newParty;
        try {
            const response = await api.examOrderParties.updateOrderPartyApi(
                orderId,
                partyId,
                partyData
            );
            dispatch(updateLegalEntity(response));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Update order party: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Add a new parcel to a property
 * @param {string} orderId ID of the order
 * @param {string} propertyId ID of the property to add the parcel to
 * @param {string} value Parcel number
 * @returns {AppThunk}
 */
export const addPropertyParcelThunk =
    (orderId: string, propertyId: string, value: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.examProperty.addPropertyParcelApi(
                orderId,
                propertyId,
                value
            );
            dispatch(addPropertyTaxParcel({ propertyId, parcel: response }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Add property parcel: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Remove a parcel from a property
 * @param {string} orderId ID of the order
 * @param {string} propertyId ID of the property to remove the parcel from
 * @param {string} parcelId ID of the parcel to remove
 * @returns {AppThunk}
 */
export const removePropertyParcelThunk =
    (orderId: string, propertyId: string, parcelId: string): AppThunk =>
    async (dispatch) => {
        try {
            await api.examProperty.removePropertyParcelApi(orderId, propertyId, parcelId);
            dispatch(removePropertyTaxParcel({ propertyId, parcelId }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Remove property parcel: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update the value of an existing parcel
 * @param {string} orderId ID of the order
 * @param {string} propertyId ID of the property that contains the parcel
 * @param {string} parcelId ID of the parcel to update
 * @param {string} value Updated value
 * @returns {AppThunk}
 */
export const updatePropertyParcelThunk =
    (orderId: string, propertyId: string, parcelId: string, value: string): AppThunk =>
    async (dispatch) => {
        try {
            await api.examProperty.updatePropertyParcelApi(
                orderId,
                propertyId,
                parcelId,
                value
            );
            dispatch(updatePropertyTaxParcel({ propertyId, parcelId, value }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Update property parcel: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update the address fields of an existing property
 * @param {string} orderId ID of the order
 * @param {string} propertyId ID of the property
 * @param {Address} updatedAddress Address object with updated fields
 * @returns {AppThunk}
 */
export const updatePropertyAddressThunk =
    (orderId: string, propertyId: string, updatedAddress: Address): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.examProperty.updatePropertyAddressApi(
                orderId,
                propertyId,
                updatedAddress
            );
            dispatch(updatePropertyAddress({ propertyId, addressChanges: response }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Update property address: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Add a legal description to an existing property
 * @param {string} orderId ID of the order
 * @param {string} propertyId ID of the property
 * @param {Omit<PropertyLegalDescription, 'id'>} legalDescriptionValue Legal description object to be added to the property
 * @returns {AppThunk}
 */
export const addPropertyLegalDescriptionThunk =
    (
        orderId: string,
        propertyId: string,
        legalDescriptionValue: Omit<PropertyLegalDescription, 'id'>
    ): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.examProperty.addPropertyLegalDescriptionApi(
                orderId,
                propertyId,
                legalDescriptionValue
            );
            dispatch(addPropertyLegalDescription({ propertyId, legalDescription: response }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Add property legal description: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update the fields of a legal description in an existing property
 * @param {string} orderId ID of the order
 * @param {string} propertyId ID of the property
 * @param {string} legalDescriptionId ID of the legal description to update
 * @param {Omit<PropertyLegalDescription, 'id'>} legalDescriptionValue Legal description object with updated fields
 * @returns {AppThunk}
 */
export const updatePropertyLegalDescriptionThunk =
    (
        orderId: string,
        propertyId: string,
        legalDescriptionId: string,
        legalDescriptionValue: Omit<PropertyLegalDescription, 'id'>
    ): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.examProperty.updatePropertyLegalDescriptionApi(
                orderId,
                propertyId,
                legalDescriptionId,
                legalDescriptionValue
            );
            dispatch(
                updatePropertyLegalDescription({
                    propertyId,
                    legalDescriptionId,
                    legalDescriptionData: response
                })
            );
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Update property legal description: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Remove a legal description from an existing property
 * @param {string} orderId ID of the order
 * @param {string} propertyId ID of the property
 * @param {string} legalDescriptionId ID of the legal description to remove
 * @returns {AppThunk}
 */
export const removePropertyLegalDescriptionThunk =
    (orderId: string, propertyId: string, legalDescriptionId: string): AppThunk =>
    async (dispatch) => {
        try {
            await api.examProperty.removePropertyLegalDescriptionApi(
                orderId,
                propertyId,
                legalDescriptionId
            );
            dispatch(removePropertyLegalDescription({ propertyId, legalDescriptionId }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Remove property legal description: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Add a new property with blank fields to an order
 * @param {string} orderId ID of the order to add a property to
 * @returns {AppThunk}
 */
export const addExamPropertyThunk =
    (orderId: string): AppThunk =>
    async (dispatch) => {
        const newProperty = {
            address: {
                streetNumber: '',
                streetName: '',
                streetDirection: '',
                streetSuffix: '',
                city: '',
                zip: '',
                unit: ''
            },
            legals: [] as PropertyLegalDescription[],
            parcelIds: [] as PropertyIdentifier[]
        };
        try {
            const response = await api.examProperty.addExamPropertyApi(orderId, newProperty);
            dispatch(addNewProperty(response));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Remove property legal description: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Fetch project data related to an order
 * @param {string} orderId ID of the order to fetch data for
 * @returns {AppThunk}
 */
export const fetchProjectOrder =
    (orderId: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const response = await api.orders.getOrderById(orderId);
            const { profileData, workbenchStatusData } = getState();
            const loggedInUser = profileData?.profile;

            const isInProgressOrInProgressPaused =
                response.projectStatus.id === OrderStatusesIds.InProgressPaused ||
                response.projectStatus.id === OrderStatusesIds.InProgress;

            const isOldOrderInitiatedWithoutAnExaminer =
                isInProgressOrInProgressPaused &&
                (response.assignedTo.id === DEFAULT_GUID || response.assignedTo.id === null);

            const isOtherExaminer =
                isInProgressOrInProgressPaused &&
                response.assignedTo.id !== loggedInUser?.id &&
                !isOldOrderInitiatedWithoutAnExaminer;

            if (
                (isOtherExaminer || response.isCompleted) &&
                !workbenchStatusData?.forceEditable
            ) {
                dispatch(setReadOnlyView(true));
            }
            dispatch(setCurrentProjectOrder(response));
            dispatch(setWorkbenchStatus(response.projectStatus.displayName));
            if (response.projectStatus.displayName === 'On Hold') {
                dispatch(setWorkbenchPauseReason(response.latestProjectEventLog?.notes));
                dispatch(
                    setIsOnHoldViewVisible(
                        response.projectStatus.displayName === 'On Hold' &&
                            response.latestProjectEventLog?.notes !== ''
                    )
                );
                dispatch(setReadOnlyView(response.projectStatus.displayName === 'On Hold'));
            }
        } catch (err) {
            dispatch(setCurrentProjectOrder(null));
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get project order: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update the status of an existing order
 * @param {string} orderId ID of the order to fetch data for
 * @param {string} statusId ID of the status to assign to the order
 * @param {string} notes Pause reason
 * @returns {AppThunk}
 */
export const updateExamOrderStatus =
    (orderId: string, statusId: string, notes?: string): AppThunk =>
    async (dispatch) => {
        try {
            if (
                (notes &&
                    (statusId === OrderStatusesIds.OnHold ||
                        statusId === OrderStatusesIds.InProgressPaused)) ||
                statusId === OrderStatusesIds.InProgress ||
                statusId === OrderStatusesIds.Open
            ) {
                const response = await api.orders.updateOrderStatus(orderId, statusId, notes);
                dispatch(fetchAllowedStatuses(orderId));
                dispatch(setExamOrderStatus(response.projectStatus));
                dispatch(setWorkbenchStatus(response.projectStatus.displayName));
            } else {
                dispatch(
                    setWorkbenchStatus(
                        statusId === OrderStatusesIds.OnHold ? 'On Hold' : 'Pause'
                    )
                );
            }
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Update exam order status: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update the effectuve date of an existing order
 * @param {string} orderId ID of the order to fetch data for
 * @param {string} effectiveDate ISO date string representing the new effective date
 * @returns {AppThunk}
 */
export const updateEffectiveDate =
    (orderId: string, effectiveDate: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.examOrder.updateEffectiveDate(orderId, effectiveDate);
            dispatch(setEffectiveDate(response?.effectiveDate));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Update effective date: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

export const {
    setCurrentExamOrder,
    addNewProperty,
    addLegalEntity,
    removeLegalEntity,
    updateLegalEntity,
    updatePropertyAddress,
    addPropertyTaxParcel,
    removePropertyTaxParcel,
    updatePropertyTaxParcel,
    addPropertyLegalDescription,
    updatePropertyLegalDescription,
    removePropertyLegalDescription,
    setCurrentProjectOrder,
    setExamOrderStatus,
    setEffectiveDate
} = currentExamOrderSlice.actions;

export default currentExamOrderSlice.reducer;
