import api from 'core/api/api';
import { LegalDescriptionValue } from 'types/dataModels';
import { LegalDescriptionResultDto } from 'types/dto/legalDescription';
import { ValueDocLink } from 'types/dataModels';
/**
 * Get order legal description by orderId
 * @function
 * @category API
 * @param {string} orderId - Current order id
 * @returns {LegalDescriptionValue[]}
 */
export const getExamOrderLegalDescription = async (
    orderId: string
): Promise<LegalDescriptionValue[]> => {
    try {
        const response = await api.get<LegalDescriptionValue[]>(
            `/api/examOrder/${orderId}/legalDescription`
        );
        return response.data;
    } catch (e) {
        console.error(e.message);
        throw e;
    }
};

/**
 * Save current legal value as legal value for order
 * @function
 * @category API
 * @param {string} orderId - Current order id
 * @param {string} value - Value from editor
 * @param {ValueDocLink[]} docLinks - Array of document links
 * @returns {LegalDescriptionResultDto}
 */
export const saveFinalOrderLegal = async (
    orderId: string,
    value: string,
    docLinks: ValueDocLink[]
): Promise<LegalDescriptionResultDto> => {
    try {
        const response = await api.put<
            {
                examValue: string;
                examValueDocLinks: ValueDocLink[];
            },
            LegalDescriptionResultDto
        >(`/api/examOrder/${orderId}/legalDescription/exam`, {
            examValue: value,
            examValueDocLinks: docLinks
        });
        return response.data;
    } catch (e) {
        console.error(e.message);
        throw e;
    }
};

/**
 * Apply current instrument as legal description for order
 * @function
 * @category API
 * @param {string} orderId - Current order id
 * @param {string} legalId - Target legal id
 * @returns {LegalDescriptionResultDto}
 */
export const applyInstrumentAsOrderLegal = async (
    orderId: string,
    legalId: string
): Promise<LegalDescriptionResultDto> => {
    try {
        const response = await api.put<null, LegalDescriptionResultDto>(
            `/api/examOrder/${orderId}/legalDescription/${legalId}/apply`
        );
        return response.data;
    } catch (e) {
        console.error(e.message);
        throw e;
    }
};
