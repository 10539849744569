const colors = {
    main: {
        primaryDark: '#1D1D1F',
        primaryLight: '#FFFFFF',
        secondaryDark: '#3A404C',
        secondaryLight: '#D8D9DD',
        tertiaryDark: '#1565C0',
        tertiaryLight: '#E8E2F6',
        accent: '#483D91',
        accent15: '#E4E2EF',
        accent5: '#F6F5FA',
        accentDark: '#372C80',
        accentHighlight: '#643BC4',
        accentHighlight15: '#E8E2F6',
        accentHighlight15_light: '#F8F5FE',
        accentHighlight5: '#F7F5FC'
    },
    additional: {
        error_fail: '#DB0001',
        warning_process: '#FF8E1C',
        success_win: '#007A4F',
        information: '#006AA0',
        informationLight: '#BFF7F7',
        error_fail_dark: '#A20000',
        warning_process_dark: '#DE6F00',
        success_win_dark: '#004931',
        information_dark: '#00486C'
    },
    neutral: {
        neutralDark: '#565961',
        neutralMid: '#8A92A3',
        neutralLight: '#EDECF1'
    },
    documents: {
        court: '#FFCCCC',
        courtDivorce: '#E6A9EC',
        countyTax: '#DFCFCF',
        lien: '#ED9595',
        lienLisPendens: '#C3FDB8',
        mortgage: '#FFE455',
        otherFile: '#D8D9DD4D',
        patriots: '#FF0000',
        transfer: '#BDEDFF',
        vestingDeed: '#BDEDFF'
    }
};

export default colors;
