import { CodeTemplate } from 'types/codebook';
import api from '../api';

/**
 * It gets a list of code templates from the server
 * @function
 * @category API
 * @subcategory examCodes
 * @param {string} orderId - string - the order id of the exam
 * @returns {CodeTemplate[]} An array of CodeTemplate objects.
 */
export const getCodeTemplates = async (orderId: string): Promise<CodeTemplate[]> => {
    try {
        const response = await api.getWithCache<CodeTemplate[]>(
            `/api/order/${orderId}/exceptions/codeBook/templates`
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};

/**
 * It gets a list of codes from the code book for a given order
 * @function
 * @category API
 * @param {string} orderId - string - the order id of the exam
 * @returns {string[]} An array of strings.
 */
export const getCodeBookCodes = async (orderId: string): Promise<string[]> => {
    try {
        const response = await api.get<string[]>(
            `/api/order/${orderId}/exceptions/codeBook/codes`
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};
