import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { KeyDocumentsRequiredFields, NewKeyDocumentForm } from 'types/dataModels';
import { ReferenceInputNames } from 'core/constants/common';

type FileUploadErrorType = {
    error: boolean;
    message: string;
};

interface IUploadDocumentFormSlice {
    newKeyDocFormState: NewKeyDocumentForm;
    fileUploadError: FileUploadErrorType;
    /**
     * Set to true after the user submits the form for the first time.
     */
    isDirty: boolean;
    isUploadDocModalOpen: boolean;
}

export const initialState: IUploadDocumentFormSlice = {
    newKeyDocFormState: {
        book: '',
        liber: '',
        page: '',
        instrumentNumber: '',
        recordedDate: '',
        instrumentDate: '',
        datedDate: '',
        instrumentYear: null,
        parties: [
            {
                id: uuidv4(),
                fieldNameId: uuidv4(),
                isGrantee: false,
                displayValue: '',
                role: 'Grantor'
            },
            {
                id: uuidv4(),
                fieldNameId: uuidv4(),
                isGrantee: true,
                displayValue: '',
                role: 'Grantee'
            }
        ],
        referenceInput: {
            [ReferenceInputNames.Book]: '',
            [ReferenceInputNames.Page]: '',
            [ReferenceInputNames.InstrumentNumber]: '',
            [ReferenceInputNames.InstrumentYear]: null
        },
        foundReferencedDocuments: [],
        notExistingReferencedDocuments: [],
        amount: null,
        transferTaxAmount: null,
        notes: '',
        files: [],
        codes: [],
        canSubmitForm: false,
        requiredFieldErrors: {
            documentType: false, // It has a default value.
            categoryId: false,
            subCategoryId: false,
            recordedDate: false,
            instrumentDate: false
        }
    },
    fileUploadError: {
        error: false,
        message: ''
    },
    isDirty: false,
    isUploadDocModalOpen: false
};

const uploadDocumentFormSlice = createSlice({
    name: 'uploadDocumentForm',
    initialState,
    reducers: {
        /**
         * Set form data for new key document
         * @param state Slice state
         * @param action Payload with form data for new key document
         */
        setNewKeyDocUploadDocumentFormState(
            state: IUploadDocumentFormSlice,
            action: PayloadAction<IUploadDocumentFormSlice['newKeyDocFormState']>
        ) {
            state.newKeyDocFormState = action.payload;
        },
        /**
         * Set file upload error
         * @param state Slice state
         * @param action Payload with form data for new key document
         */
        setFileUploadError(
            state: IUploadDocumentFormSlice,
            action: PayloadAction<FileUploadErrorType>
        ) {
            state.fileUploadError = action.payload;
        },
        /**
         * Set required fields errors
         * @param state Slice state
         * @param action Payload with required fields error state
         */
        setRequiredFieldErrors(
            state: IUploadDocumentFormSlice,
            action: PayloadAction<KeyDocumentsRequiredFields>
        ) {
            state.newKeyDocFormState.requiredFieldErrors = action.payload;
        },
        setIsFormDirty(state: IUploadDocumentFormSlice, action: PayloadAction<boolean>) {
            state.isDirty = action.payload;
        },
        /**
         * Set upload doc modal open/close flag
         * @param state Slice state
         * @param action Payload with isOpen flag
         */
        setUploadDocModalOpen(
            state: IUploadDocumentFormSlice,
            action: PayloadAction<boolean>
        ) {
            state.isUploadDocModalOpen = action.payload;
        }
    }
});

export const {
    setNewKeyDocUploadDocumentFormState,
    setFileUploadError,
    setRequiredFieldErrors,
    setIsFormDirty,
    setUploadDocModalOpen
} = uploadDocumentFormSlice.actions;

export default uploadDocumentFormSlice.reducer;
