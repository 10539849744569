export const MSAL_CONFIG = 'msalConfig';
export const ALPHABETICAL_PATTERN = 'a-zA-Z';
export const NUMERIC_PATTERN = '0-9';
export const SPECIAL_SYMBOLS = `$%'"_@~!=&\\-\\,.\\]\\[`;
export const HYPHEN = '-';
export const INT_MAX_NUMBER = 2147483647;
export const MAX_SINGLE_DOCUMENT_SIZE_IN_BYTES = 104857600;
export const MAX_ALL_DOCUMENTS_SIZE_IN_BYTES = 209715200;
export const CORRECT_FILE_TYPES_FOR_UPLOAD = [
    'image/tiff',
    'application/pdf',
    'text/html',
    'application/msword',
    'application/rtf',
    'text/plain'
];
export const DEFAULT_FILE_UPLOAD_ERROR =
    'Uploading error. Something went wrong. Try uploading the file again.';

export enum WorkbenchTabsEnum {
    searchReport = 'Search Report',
    orderDetails = 'Order Details',
    vestingOwners = 'Vesting',
    legalDescription = 'Legal Description',
    taxes = 'Taxes',
    exceptionsRequirements = 'Exceptions/Requirements'
}

export enum WorkbenchOrderDetailsSubTabsEnum {
    parties = 'Parties',
    generalInfo = 'General Info',
    propertyDetail = 'Properties Details'
}

export const WORKBENCH_TABS_NAMES = [
    WorkbenchTabsEnum.searchReport,
    WorkbenchTabsEnum.orderDetails,
    WorkbenchTabsEnum.vestingOwners,
    WorkbenchTabsEnum.legalDescription,
    WorkbenchTabsEnum.taxes,
    WorkbenchTabsEnum.exceptionsRequirements
];

export const WORKBENCH_TABS_NEED_APPROVAL = [
    WorkbenchTabsEnum.searchReport,
    WorkbenchTabsEnum.vestingOwners,
    WorkbenchTabsEnum.legalDescription
];

export enum OrderReviewStateTypes {
    alert,
    question,
    completed,
    bell
}
export enum TestOrderFormTypes {
    pulse = 'Pulse(Standard Order)',
    resware = 'ResWare'
}

export enum ButtonVariants {
    primary = 'primary',
    secondary = 'secondary',
    success = 'success'
}

export enum InputLabelPositions {
    top = 'top',
    left = 'left'
}

export enum DatePickerViews {
    year = 'year',
    month = 'month',
    day = 'day'
}

export enum StatusIconVariants {
    Automation,
    InProgress,
    InProgressPaused,
    Open,
    Completed,
    Canceled,
    OnHold,
    Rush
}

export enum WorkbenchPanelStatusIconVariants {
    notification = 'notification',
    needConfirmation = 'needConfirmation',
    alert = 'alert'
}

export enum SidebarPageNames {
    dashboard = 'Dashboard',
    workbench = 'Workbench',
    orders = 'Orders',
    history = 'History',
    neworder = 'New Order',
    messages = 'Messages',
    support = 'Support'
}

export enum OrdersListTableHeaders {
    orderNumber = 'Order №',
    status = 'Order Status',
    action = 'Action',
    stateCounty = 'State-County',
    sla = 'SLA',
    businessSegment = 'Business Segment',
    product = 'Product',
    agent = 'Agent',
    assignedTo = 'Assigned to',
    parcelNumber = 'APN/ Parcel №',
    owners = 'Owners'
}

export enum UploadDocParentPages {
    taxes = 'taxes',
    tsri = 'tsri',
    searchPackage = 'searchPackage'
}

export enum DocumentTypes {
    tax = 'Tax',
    assessor = 'Assessor',
    recorded = 'Recorded',
    maps = 'Maps',
    other = 'Other',
    searchPackage = 'Search Package'
}

export enum IconSizes {
    small = 'small',
    large = 'large'
}

export enum ExceptionsRequirementsPanelTypes {
    Requirements = 1,
    Exceptions = 0
}

export enum DocumentModalErrorMessages {
    incorrectFileSize = 'Incorrect file size',
    incorrectFileFormat = 'Incorrect file format',
    incorrectFileFormatAndSize = 'Incorrect file format and file size',
    withoutError = ''
}

/**
 * Document untagging warning messages
 */
export enum KeyDocumentUnTaggingModalWarningMessages {
    vesting = 'Document is the approved source for vesting.',
    legalDescription = 'Document is the approved source for legal.',
    vestingNLegalDescription = 'Document is the approved source for vesting and legal.',

    allDocsOfGroup = 'All documents in group will be untagged.',
    allDocsInclusiveVestingNLegal = 'All documents in group will be untagged. This group includes approved source for vesting and legal.',
    allDocsInclusiveVesting = 'All documents in group will be untagged. This group includes approved source for vesting.',
    allDocsInclusiveLegal = 'All documents in group will be untagged. This group includes approved source for legal.',

    allChildDocsInclusiveVestingNLegal = 'All child documents will be untagged. These documents includes approved source for vesting and legal.',
    allChildDocsInclusiveVesting = 'All child documents will be untagged. These documents includes approved source for vesting.',
    allChildDocsInclusiveLegal = 'All child documents will be untagged. These documents includes approved source for legal.'
}

/**
 * Workbench sidebar panels names
 */
export enum WorkbenchSidebarSubpanelsNames {
    searchCopyOptions = 'Search / Copy options / Customer requirements',
    manualSearch = 'Manual Search',
    orderHistory = 'Order History',
    keyDocumentsGrouping = 'Key Documents Grouping',
    searchPackage = 'Search Package',
    warnings = 'Warnings'
}

/**
 * Document categories
 */
export enum DocumentCategories {
    CORPORATION = 'CORPORATION',
    RELEASE = 'RELEASE',
    PATRIOTS = 'PATRIOTS',
    STATEBAR = 'STATEBAR',
    CCER = 'CCER',
    MODIFICATION = 'MODIFICATION',
    MASTERDEEDANDBYLAWS = 'MASTERDEEDANDBYLAWS',
    ASSIGNMENT = 'ASSIGNMENT',
    OTHERFILE = 'OTHERFILE',
    WHITEPAGES = 'WHITEPAGES',
    ASSUMPTION = 'ASSUMPTION',
    LIEN = 'LIEN',
    MORTGAGE = 'MORTGAGE',
    APPOINTMENT = 'APPOINTMENT',
    DRIVERDATA = 'DRIVERDATA',
    SUBSTITUTION = 'SUBSTITUTION',
    PRIORFILE = 'PRIORFILE',
    MASTERDEED = 'MASTERDEED',
    CITYTAX = 'CITYTAX',
    SUBORDINATION = 'SUBORDINATION',
    RECORDMAP = 'RECORDMAP',
    HOA = 'HOA',
    PARCELMAP = 'PARCELMAP',
    EASEMENT = 'EASEMENT',
    AFFIDAVIT = 'AFFIDAVIT',
    TRANSFER = 'TRANSFER',
    COURT = 'COURT',
    LEXISNEXIS = 'LEXISNEXIS',
    ASSESSOR = 'ASSESSOR',
    COMPLAINT = 'COMPLAINT',
    COUNTYTAX = 'COUNTYTAX',
    RQNOTICE = 'RQNOTICE',
    TRACTMAP = 'TRACTMAP',
    RESTRICTION = 'RESTRICTION',
    LEASE = 'LEASE',
    BYLAWS = 'BYLAWS',
    PLAT = 'PLAT',
    MISCELLANEOUS = 'MISCELLANEOUS'
}

export const whitelistedKeyDocumentCategories: Array<keyof typeof DocumentCategories> = [
    DocumentCategories.HOA,
    DocumentCategories.DRIVERDATA,
    DocumentCategories.LEXISNEXIS
];

export const grantorParties = ['Grantor Lender', 'Grantor Lender Nominee', 'Grantor Trustee'];
export const granteeParties = [
    'Grantees Lender',
    'Grantees Lender Nominee',
    'Grantees Trustee'
];

export enum DroppableSections {
    Exceptions = 'exceptions',
    Requirements = 'requirements',
    Codebook = 'codebook',
    TaxCodes = 'taxCodes',
    Legal = 'legal',
    Vesting = 'vesting',
    FullScreenLegal = 'fullScreenLegal',
    FullScreenVesting = 'fullScreenVesting',
    UploadDocModal = 'uploadDocModal',
    FullScreenDocumentRows = 'fullScreenDocumentRows'
}

export enum SnackbarCloseReason {
    Timeout = 'timeout',
    AutoHideDuration = 'autoHideDuration',
    Clickaway = 'clickaway',
    EscapeKeyDown = 'escapeKeyDown'
}

export enum SnackbarSeverity {
    Error = 'error',
    Warning = 'warning',
    Info = 'info',
    Success = 'success'
}

export enum AlertVariant {
    Filled = 'filled',
    Outlined = 'outlined',
    Standard = 'standard'
}

export enum DocumentGroup {
    keyDocument,
    searchPackage
}

export enum OrdersListTableSorting {
    orderNumber = 'ProjectOrder.FileNumber',
    status = 'ProjectStatusId',
    sla = 'TimeLeft.Hours',
    action = 'Action',
    stateCounty = 'OrderId',
    product = 'ProductTypeId',
    businessSegment = 'ProductType.BusinessSegment.Id',
    assignedTo = 'AssignedTo.DisplayName'
}

export enum TaxCategories {
    CityTax = 'CITYTAX',
    CountyTax = 'COUNTYTAX'
}

export enum TaxInstallmentStatuses {
    open = 'Open',
    paid = 'Paid',
    delinquent = 'Delinquent'
}

export enum GlobalStatusFilter {
    CompletedExcluded = 'Completed excluded',
    OnlyCompleted = 'Only completed',
    AllOrders = 'All statuses'
}

export enum OrderStatusesIds {
    New = '7d31510a-ce9f-4c86-9443-aa593a197cf0',
    Rejected = '9e703f2b-d9f4-4038-a3dc-0b099de02a82',
    WaitForActivate = '031555b1-b0dd-46e4-b502-4f7c67ba3620',
    Searching = '0a00c3f0-bb9e-41dd-bcdf-88d8e690f678',
    Open = 'ed135ec9-b263-48ac-83f2-719a2170f1b9',
    InProgress = '02a50073-6f22-4e37-ab77-dc0c25904ad6',
    SearchCompleted = '210d8fee-6e8b-4a78-a296-3d277b5e9827',
    Completed = 'fd636b59-a009-49d5-9be6-4c93d1bb8e9b',
    Canceled = '8183c613-3929-4a89-a04f-5e10512bbd40',
    Active = 'ec9ea30e-544d-4274-81d1-6e228a379939',
    OnHold = 'c32716ec-e5fa-4c30-97e3-9eaa1e2e7e6a',
    ReassignedToVendor = '437654a1-e7c8-4089-bec0-c9cd913c3085',
    InProgressPaused = '48bacf11-768d-4b01-a1ab-a1d02cb5025a'
}
export const COMPLETED_EXCLUDED_FILTERS = [
    OrderStatusesIds.New,
    OrderStatusesIds.Rejected,
    OrderStatusesIds.WaitForActivate,
    OrderStatusesIds.Searching,
    OrderStatusesIds.Open,
    OrderStatusesIds.InProgress,
    OrderStatusesIds.SearchCompleted,
    OrderStatusesIds.Canceled,
    OrderStatusesIds.Active,
    OrderStatusesIds.OnHold,
    OrderStatusesIds.ReassignedToVendor,
    OrderStatusesIds.InProgressPaused
];

export const COMPLETED_FILTERS = [OrderStatusesIds.Completed];

export const statusIconsMapping = {
    [OrderStatusesIds.Rejected]: StatusIconVariants.Automation,
    [OrderStatusesIds.SearchCompleted]: StatusIconVariants.Automation,
    [OrderStatusesIds.Completed]: StatusIconVariants.Completed,
    [OrderStatusesIds.WaitForActivate]: StatusIconVariants.Automation,
    [OrderStatusesIds.Canceled]: StatusIconVariants.Canceled,
    [OrderStatusesIds.Active]: StatusIconVariants.InProgress,
    [OrderStatusesIds.Open]: StatusIconVariants.Open,
    [OrderStatusesIds.Searching]: StatusIconVariants.Automation,
    [OrderStatusesIds.OnHold]: StatusIconVariants.OnHold,
    [OrderStatusesIds.New]: StatusIconVariants.Automation,
    [OrderStatusesIds.ReassignedToVendor]: StatusIconVariants.OnHold,
    [OrderStatusesIds.InProgress]: StatusIconVariants.InProgress,
    [OrderStatusesIds.InProgressPaused]: StatusIconVariants.InProgressPaused
};

export const globalStatusFilterMapping = {
    [GlobalStatusFilter.CompletedExcluded]: COMPLETED_EXCLUDED_FILTERS,
    [GlobalStatusFilter.OnlyCompleted]: COMPLETED_FILTERS,
    [GlobalStatusFilter.AllOrders]: [] as string[]
};

export const defaultAlertState = {
    isAlertOpen: false,
    headerText: '',
    bodyText: ''
};

export enum ReferenceInputNames {
    Book = 'book',
    Page = 'page',
    InstrumentNumber = 'instrumentNumber',
    InstrumentYear = 'instrumentYear'
}

export enum AmountTypes {
    Amount = 'hasAmountField',
    TransferTaxAmount = 'hasTransferTaxAmountField',
    AssessorAmount = 'hasAssessorAmountFields',
    TaxAmount = 'hasTaxAmountFields'
}

export enum TextSizeOptions {
    UPPER = 'upper',
    TITLE = 'title',
    LOWER = 'lower'
}

export enum FieldValueTypes {
    Boolean = 0,
    Object = 1,
    Int32 = 10,
    Int64 = 11,
    Double = 12,
    Decimal = 13,
    DateTime = 20,
    DateTimeOffset = 21,
    String = 30,
    DocumentParty = 100,
    DocumentInstallment = 101
}

export enum InputTypes {
    Date = 'DATE',
    Text = 'TEXT',
    Amount = 'AMOUNT',
    Vesting = 'VESTING'
}

export const DEFAULT_GUID = '00000000-0000-0000-0000-000000000000';

export const DEFAULT_REQUIRED_FIELD_MESSAGE = 'Required Field';

export const ORDER_STATUSES = ['Check in', 'On Hold', 'Pause'];

/**
 * MAx length input field validation for Book | Page | Instrument No | Parcel No
 */
export const maxLength = 30;
