import { AxiosResponse } from 'axios';
import api from '../api';

/**
 * This function is used to tag or untag all files in a document
 * @function
 * @category API
 * @subcategory documentFileTag/Untag
 * @param {string} orderId - string,
 * @param {string} docId - The document id
 * @param {boolean} isTagged - boolean
 * @returns {string} A string
 */
export const apiSetIncludeAttachments = async (
    orderId: string,
    docId: string,
    isTagged: boolean
): Promise<string> => {
    try {
        const response: AxiosResponse<string> = await api.put(
            `/api/examOrder/${orderId}/document/${docId}/files/includeAttachments`,
            {
                IncludeAttachment: isTagged
            }
        );
        return response.data;
    } catch (e) {
        console.error(e.message);
    }
};
