import {
    CommonDocument,
    CreatePulseDocumentFileDto,
    KeyDocument,
    NewCommonDocumentWithFilesDto,
    NewKeyDocumentForm,
    NewRecordedDocumentWithFilesDto,
    UpdateRecordedDocumentDto
} from 'types/dataModels';
import api from 'core/api/api';
import {
    createDataForRecordedDocumentUpload,
    createDataForCommonDocumentUpload
} from 'core/helpers/createDataForRecordedDocumentUpload';
import { updateDataForRecordedExistingDocument } from 'core/helpers/updateDataForRecordedDocumentUpload';
import { FileUploadError } from 'core/helpers/errors';

/**
 * Creates new recorded document from user input form (second step of document creation after uploading images)
 * @function
 * @category API
 * @param {NewKeyDocumentForm} documentData - Data from input form
 * @param {CreatePulseDocumentFileDto[]} uploadedFiles - Array of files for upload
 * @param {string} orderId - Current order id
 * @param {string} propertyId - Document property id
 * @param {string} categoryId - Document category id
 * @param {string} subCategoryId - Documents subcategory id
 * @param {string} searchType - Document search type
 * @param {string} argumentsCompositeValue - Document argument composite value
 * @returns {CommonDocument}
 */
export const uploadRecordedDocumentWithNoImage = async (
    documentData: NewKeyDocumentForm,
    uploadedFiles: CreatePulseDocumentFileDto[],
    orderId: string,
    propertyId: string,
    categoryId: string,
    subCategoryId: string,
    searchType = '',
    argumentsCompositeValue = ''
): Promise<CommonDocument> => {
    const resultFormData = createDataForRecordedDocumentUpload(
        uploadedFiles,
        categoryId,
        subCategoryId,
        documentData,
        propertyId,
        searchType,
        argumentsCompositeValue
    );
    try {
        const response = await api.post<NewRecordedDocumentWithFilesDto, CommonDocument>(
            `/api/examOrder/${orderId}/document/recorded/withNoImage`,
            resultFormData
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};

export const updateExistingRecordedDocument = async (
    documentData: NewKeyDocumentForm,
    orderId: string,
    document: KeyDocument,
    propertyId: string,
    categoryId: string,
    subCategoryId: string
): Promise<CommonDocument> => {
    const { isFileUpdated, uploadRequestPayload } = updateDataForRecordedExistingDocument(
        categoryId,
        subCategoryId,
        documentData,
        propertyId,
        document
    );
    let response;
    if (isFileUpdated) {
        try {
            response = await api.put<FormData | UpdateRecordedDocumentDto, CommonDocument>(
                `/api/examOrder/${orderId}/document/${document.id}/recordedWithFiles`,
                uploadRequestPayload
            );
            return response.data;
        } catch (err) {
            throw new FileUploadError(err.message);
        }
    } else {
        try {
            response = await api.put<FormData | UpdateRecordedDocumentDto, CommonDocument>(
                `/api/examOrder/${orderId}/document/${document.id}/recorded`,
                uploadRequestPayload
            );
            return response.data;
        } catch (err) {
            throw new Error(err.message);
        }
    }
};

/**
 * Creates new common document from user input form (second step of document creation after uploading images)
 * @function
 * @category API
 * @param {NewKeyDocumentForm} documentData - Data from input form
 * @param {CreatePulseDocumentFileDto[]} uploadedFiles - Array of files for upload
 * @param {string} orderId - Current order id
 * @param {string} propertyId - Document property id
 * @param {string} categoryId - Document category id
 * @param {string} subCategoryId - Documents subcategory id
 * @returns {CommonDocument}
 */
export const uploadCommonDocumentWithNoImage = async (
    documentData: NewKeyDocumentForm,
    uploadedFiles: CreatePulseDocumentFileDto[],
    orderId: string,
    propertyId: string,
    categoryId: string,
    subCategoryId: string
): Promise<CommonDocument> => {
    const resultFormData = createDataForCommonDocumentUpload(
        uploadedFiles,
        categoryId,
        subCategoryId,
        documentData,
        propertyId
    );
    try {
        const response = await api.post<NewCommonDocumentWithFilesDto, CommonDocument>(
            `/api/examOrder/${orderId}/document/common/withNoImage`,
            resultFormData
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};
