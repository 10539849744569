import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { lazy, Suspense, useEffect } from 'react';
import LoadingSpinner from 'components/common/LoadingSpinner';
import {
    DASHBOARD,
    TEST_ORDER_FORM,
    ORDERS,
    TEST_ORDERS,
    WORKBENCH,
    WORKBENCH_FORCE_EDITABLE
} from 'core/constants/navigation';
import { useAppDispatch } from 'core/hooks/useAppDispatch';
import { fetchClient } from 'core/features/client/clientSlice';
import { fetchStates } from 'core/features/states/statesSlice';
import { fetchCounty } from 'core/features/county/countySlice';
import { fetchProductTypes } from 'core/features/productTypes/productTypesSlice';
import { fetchProjectStatuses } from 'core/features/projectStatus/projectStatusSlice';
import { fetchDocumentTypes } from 'core/features/documentTypes/documentTypesSlice';
import { fetchBusinessSegments } from 'core/features/businessSegments/businessSegmentsSlice';
import { fetchProfileData } from 'core/features/profile/profileSlice';
import { fetchInterestEstateTypes } from 'core/features/interestEstateType/interestEstateTypeSlice';

const Dashboard = lazy(() => import('pages/Dashboard'));
const Orders = lazy(() => import('pages/Orders'));
const TestOrderForm = lazy(() => import('pages/TestOrders/TestOrderForm'));
const Workbench = lazy(() => import('pages/Workbench'));

/**
 * Authorized view wrapper for application
 * @component
 * @returns {JSX.Element}
 */
export const AuthorizedView = () => {
    const dispatch = useAppDispatch();
    /**
     * initial data loading
     */
    useEffect(() => {
        dispatch(fetchClient());
        dispatch(fetchStates());
        dispatch(fetchCounty());
        dispatch(fetchProductTypes());
        dispatch(fetchProjectStatuses());
        dispatch(fetchDocumentTypes());
        dispatch(fetchBusinessSegments());
        dispatch(fetchProfileData());
        dispatch(fetchInterestEstateTypes());
    }, []);
    return (
        <Routes>
            <Route
                path={DASHBOARD}
                element={
                    <Suspense fallback={<LoadingSpinner />}>
                        <Dashboard />
                    </Suspense>
                }
            />
            <Route
                path={`${WORKBENCH}/:orderId`}
                element={
                    <Suspense fallback={<LoadingSpinner />}>
                        <Workbench />
                    </Suspense>
                }
            />
            <Route
                path={`${WORKBENCH_FORCE_EDITABLE}/:orderId`}
                element={
                    <Suspense fallback={<LoadingSpinner />}>
                        <Workbench forceEditable={true} />
                    </Suspense>
                }
            />
            <Route
                path={ORDERS}
                element={
                    <Suspense fallback={<LoadingSpinner />}>
                        <Orders />
                    </Suspense>
                }
            />
            <Route path={TEST_ORDERS} element={<Outlet />}>
                <Route
                    path={TEST_ORDER_FORM}
                    element={
                        <Suspense fallback={<LoadingSpinner />}>
                            <TestOrderForm />
                        </Suspense>
                    }
                />
            </Route>
            <Route path="*" element={<Navigate to={`/${ORDERS}`} replace />} />
        </Routes>
    );
};
