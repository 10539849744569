import { styled } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TableRow from '@mui/material/TableRow';
import colors from 'core/constants/colors';

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(2n)': {
        backgroundColor: '#643BC4'
    }
}));

export const commonStyles = makeStyles({
    link: {
        display: 'inline-block',
        color: colors.additional.information,
        fontFamily: 'Roboto',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '16px',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    linkReverse: {
        display: 'inline-block',
        color: colors.additional.information,
        fontFamily: 'Roboto',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '16px',
        cursor: 'pointer',
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'none'
        }
    },
    linkLight: {
        display: 'inline-block',
        color: colors.additional.information,
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '14px',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    }
});

export const textStylesRoboto = makeStyles({
    caption_12_regular: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '14px'
    },
    caption_12_italic: {
        fontFamily: 'Roboto',
        fontStyle: 'italic',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '14px'
    },
    caption_12_medium: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '14px'
    },
    subtitle_12_semibold: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '14px'
    },
    subtitle_12_bold: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '14px'
    },
    body_14_regular: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px'
    },
    body_strong_14_semibold: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '16px'
    },
    body_large_16_regular: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '19px'
    },
    body_large_16_semibold: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px'
    }
});

export const textStylesMontserrat = makeStyles({
    button_default: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '17px'
    },
    subtitle_12_bold: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '12px',
        lineHeight: '15px'
    },
    subtitle_16_semibold: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px'
    },
    title_20_semibold: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '24px',
        color: colors.main.primaryDark
    },
    titleLarge_24_semibold: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '29px'
    },
    display_40_semibold: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '40px',
        lineHeight: '49px'
    }
});

export { StyledTableRow };
