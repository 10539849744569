import { configureStore } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import rootReducer from './reducers';

const preloadedState = {};

const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production' && typeof window === 'object',
    preloadedState
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    AnyAction
>;

export default store;
