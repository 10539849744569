import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    DroppableSections,
    ExceptionsRequirementsPanelTypes,
    SnackbarSeverity
} from 'core/constants/common';
import { AppThunk } from 'core/store/store';
import api from 'core/api';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { removeOrderTaxDocumentCodeThunk } from 'core/features/examOrderTaxes/examOrderTaxesSlice';
import { removeDocumentCode } from 'core/features/examOrderKeyDocumentGroup/examOrderKeyDocumentGroupSlice';
import { fetchExamOrderParentSuccessorsDocumentGroupData } from 'core/features/examOrderKeyDocumentGroup/examOrderKeyDocumentGroupSlice';
import { sortParagraphsBySequence } from 'core/helpers/sortParagraphsBySequence';
import { sortCodeTemplatesByAlphabet } from 'core/helpers/sortCodeTemplatesByAlphabet';
import {
    Paragraph,
    ParagraphSequence,
    Section,
    ParagraphErrors
} from 'types/exceptionsRequirements';
import { ParagraphCreateDto, ParagraphWriteDto } from 'types/dto/exceptionsRequirementsDto';
import { CodeTemplate } from 'types/codebook';

interface AddPhraseBelowPayload {
    codeId: string;
    panelName: ExceptionsRequirementsPanelTypes;
}

interface AddPhraseVisibilityPayload {
    isVisible: boolean;
    panelName: ExceptionsRequirementsPanelTypes;
}

interface SetNumberingPayload {
    isNumbering: boolean;
    panelName: ExceptionsRequirementsPanelTypes;
}

type SectionData = {
    [key in ExceptionsRequirementsPanelTypes]: {
        addPhraseBelowIndex: number;
        isAddPhraseBelowEditorVisible: boolean;
        isNumbering: boolean;
        isAllExpanded: boolean;
    };
};

interface ExceptionsState {
    sections: SectionData;
    paragraphsList: Paragraph[];
    targetDropPanel: DroppableSections;
    paragraphErrors: ParagraphErrors[];
}

const initialState: ExceptionsState = {
    sections: {
        [ExceptionsRequirementsPanelTypes.Exceptions]: {
            addPhraseBelowIndex: 0,
            isAddPhraseBelowEditorVisible: false,
            isNumbering: false,
            isAllExpanded: false
        },
        [ExceptionsRequirementsPanelTypes.Requirements]: {
            addPhraseBelowIndex: 0,
            isAddPhraseBelowEditorVisible: false,
            isNumbering: false,
            isAllExpanded: false
        }
    },
    paragraphsList: [],
    targetDropPanel: null,
    paragraphErrors: []
};

const exceptionsSlice = createSlice({
    name: 'exceptionsRequirementsPanel',
    initialState,
    reducers: {
        /**
         * Set list of paragraphs to store
         * @param state Slice state
         * @param action Payload with the list of paragraphs to set
         */
        setParagraphList(state, action: PayloadAction<Paragraph[]>) {
            const paragraphsList = action.payload;
            paragraphsList.sort(sortParagraphsBySequence);
            state.paragraphsList = paragraphsList;
        },
        /**
         * Remove a paragraph
         * @param state Slice state
         * @param action Payload with ID of the paragraph to remove
         */
        removeParagraph(state, action: PayloadAction<string>) {
            const targetParagraphIndex = state.paragraphsList.findIndex(
                (paragraph) => paragraph.id === action.payload
            );
            state.paragraphsList.splice(targetParagraphIndex, 1);
        },
        /**
         * Update a paragraph
         * @param state Slice state
         * @param action Payload with paragraph ID and the updated paragraph object
         */
        updateParagraph(state, action: PayloadAction<{ id: string; paragraph: Paragraph }>) {
            const { paragraph, id } = action.payload;
            const targetParagraphIndex = state.paragraphsList.findIndex(
                (paragraph) => paragraph.id === id
            );
            state.paragraphsList[targetParagraphIndex] = paragraph;
        },
        /**
         * Update sequence of paragraphs
         * @param state Slice state
         * @param action Payload with the updated paragraph sequence
         */
        updateParagraphListSequence(state, action: PayloadAction<ParagraphSequence>) {
            const sequence = action.payload;
            state.paragraphsList.forEach((paragraph, index) => {
                state.paragraphsList[index].sequence = sequence[paragraph.id];
            });
            state.paragraphsList.sort(sortParagraphsBySequence);
        },
        /**
         * Set the index where a new phrase is added when user clicks 'add phrase below'
         * @param state Slice state
         * @param action Payload with the list of paragraphs to set
         */
        setAddPhraseBelowIndex(state, action: PayloadAction<AddPhraseBelowPayload>) {
            const { panelName, codeId } = action.payload;
            const requirements = state.paragraphsList.filter(
                (paragraph) =>
                    paragraph.section === ExceptionsRequirementsPanelTypes.Requirements
            );
            const exceptions = state.paragraphsList.filter(
                (paragraph) =>
                    paragraph.section === ExceptionsRequirementsPanelTypes.Exceptions
            );
            switch (panelName) {
                case ExceptionsRequirementsPanelTypes.Requirements:
                    state.sections[panelName].addPhraseBelowIndex = requirements.findIndex(
                        (el) => el.id === codeId
                    );
                    break;
                case ExceptionsRequirementsPanelTypes.Exceptions:
                    state.sections[panelName].addPhraseBelowIndex = exceptions.findIndex(
                        (el) => el.id === codeId
                    );
                    break;
            }
        },
        /**
         * Set the visibility of the 'add phrase below' legend in the exceptions or requirements panel
         * @param state Slice state
         * @param action Payload with the panel name and isVisible flag value
         */
        setAddPhraseBelowVisibility(state, action: PayloadAction<AddPhraseVisibilityPayload>) {
            const { panelName, isVisible } = action.payload;
            state.sections[panelName].isAddPhraseBelowEditorVisible = isVisible;
        },
        /**
         * Set the visibility of the numbering inputs in the exceptions or requirements panel
         * @param state Slice state
         * @param action Payload with the panel name and isNumbering flag value
         */
        setNumbering(state, action: PayloadAction<SetNumberingPayload>) {
            const { panelName, isNumbering } = action.payload;
            state.sections[panelName].isNumbering = isNumbering;
        },
        /**
         * Expand all paragraphs in the exceptions or requirements panel
         * @param state Slice state
         * @param action Payload with the panel name and isAllExpanded flag value
         */
        setAllParagraphExpanded(
            state,
            action: PayloadAction<{
                panelName: ExceptionsRequirementsPanelTypes;
                isAllExpanded: boolean;
            }>
        ) {
            const { panelName, isAllExpanded } = action.payload;
            state.sections[panelName].isAllExpanded = isAllExpanded;
        },
        /**
         * Set the target panel where a code will be dropped
         * @param state Slice state
         * @param action Payload with the name of the droppable section to set as target
         */
        setTargetDropPanel(state, action: PayloadAction<DroppableSections>) {
            state.targetDropPanel = action.payload;
        },
        /**
         *
         * @param state Slice state
         * @param action Payload with the
         */
        setParagraphErrors(state, action: PayloadAction<ParagraphErrors[]>) {
            state.paragraphErrors = action.payload;
        },
        /**
         *
         * @param state
         * @param action
         */
        addParagraphError(state, action: PayloadAction<ParagraphErrors>) {
            state.paragraphErrors.push(action.payload);
        },
        /**
         *
         * @param state
         * @param action
         */
        removeParagraphError(state, action: PayloadAction<ParagraphErrors>) {
            state.paragraphErrors.forEach((element, index) => {
                if (action.payload.id == element.id) state.paragraphErrors.splice(index, 1);
            });
        }
    }
});

export const {
    setAddPhraseBelowIndex,
    setAddPhraseBelowVisibility,
    setParagraphList,
    removeParagraph,
    updateParagraph,
    updateParagraphListSequence,
    setNumbering,
    setAllParagraphExpanded,
    setTargetDropPanel,
    setParagraphErrors,
    addParagraphError,
    removeParagraphError
} = exceptionsSlice.actions;

/**
 * Fetch exceptions and requirements data for the order
 * @param {string} orderId ID of the order
 * @returns {AppThunk}
 */
export const getAllExceptionsAndRequirementsThunk =
    (orderId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.exceptionsRequirements.getExceptions(orderId);
            dispatch(setParagraphList(response));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get all paragraphs: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Add a new paragraph (exception or requirement) to an order
 * @param {string} orderId ID of the order
 * @param {ParagraphCreateDto[]} paragraphData Paragraph object
 * @returns {AppThunk}
 */
export const addParagraphThunk =
    (orderId: string, paragraphData: ParagraphCreateDto[]): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.exceptionsRequirements.postExceptions(
                orderId,
                paragraphData
            );
            dispatch(setParagraphList(response));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Add paragraphs: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Remove a paragraph from an existing order
 * @param {string} orderId ID of the order
 * @param {string} paragraphId ID of the paragraph to remove
 * @returns {AppThunk}
 */
export const removeParagraphThunk =
    (orderId: string, paragraphId: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const paragraphList = getState().exceptionsData.paragraphsList;
            const targetParagraph = paragraphList.find((par) => par.id === paragraphId);
            await api.exceptionsRequirements.deleteParagraphById(orderId, paragraphId);
            if (targetParagraph.linkedDoc !== '00000000-0000-0000-0000-000000000000')
                if (targetParagraph.typeInfo.isTaxDocument) {
                    dispatch(
                        removeOrderTaxDocumentCodeThunk(
                            orderId,
                            targetParagraph.linkedDoc,
                            targetParagraph.codeTemplateId
                        )
                    );
                } else
                    dispatch(
                        removeDocumentCode({ docId: targetParagraph.linkedDoc, paragraphId })
                    );
            dispatch(removeParagraph(paragraphId));
            dispatch(updateSequenceThunk());
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Remove paragraph: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update a paragraph in an existing order
 * @param {string} orderId ID of the order
 * @param {string} paragraphId ID of the paragraph to update
 * @param {ParagraphWriteDto} paragraphData Updated paragraph object
 * @returns {AppThunk}
 */
export const updateParagraphThunk =
    (orderId: string, paragraphId: string, paragraphData: ParagraphWriteDto): AppThunk =>
    async (dispatch) => {
        try {
            const updatedParagraph: Paragraph =
                await api.exceptionsRequirements.updateParagraphById(
                    orderId,
                    paragraphId,
                    paragraphData
                );
            dispatch(
                updateParagraph({
                    id: paragraphId,
                    paragraph: updatedParagraph
                })
            );
            dispatch(fetchExamOrderParentSuccessorsDocumentGroupData(orderId));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Update paragraph: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update the sequence in which paragraphs are displayed in an order
 * @param {string} orderId ID of the order
 * @param {ParagraphSequence} paragraphSequence Updated paragraph sequence
 * @returns {AppThunk}
 */
export const updateSequenceListThunk =
    (orderId: string, paragraphSequence: ParagraphSequence): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateParagraphListSequence(paragraphSequence));
            const response = await api.exceptionsRequirements.updateParagraphSequence(
                orderId,
                paragraphSequence
            );
            dispatch(setParagraphList(response));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: err.message,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update the paragraph sequence by dragging and dropping a code to a new position
 * @param {number} sourceIndex Index of the code that was dragged
 * @param {number} destinationIndex Index of the position the code was dropped to
 * @param {Paragraph[]} arrayCopy Copy of the current list of paragraphs
 * @returns {AppThunk}
 */
export const reorderColumnThunk =
    (sourceIndex: number, destinationIndex: number, arrayCopy: Paragraph[]): AppThunk =>
    async (dispatch, getState) => {
        const paragraphsList = getState().exceptionsData.paragraphsList;
        const currentOrder = getState().currentExamOrderData.currentExamOrder.id;

        const movedItem = arrayCopy.find((item, index) => index === sourceIndex);
        arrayCopy.splice(sourceIndex, 1);
        arrayCopy.splice(destinationIndex, 0, movedItem);
        const newSequence: ParagraphSequence = paragraphsList.reduce(
            (acc, curr) => ({ ...acc, [curr.id]: curr.sequence }),
            {} as ParagraphSequence
        );
        arrayCopy.forEach((item, index) => {
            newSequence[item.id] = index;
        });
        dispatch(updateSequenceListThunk(currentOrder, newSequence));
    };

/**
 * Add a code to the paragraph list by dragging and dropping from the codebook
 * @param {number} sourceIndex Index of the dragged code in the codebook
 * @param {number} destinationIndex Index of the position the code was dropped to
 * @param {ExceptionsRequirementsPanelTypes} targetColumn Name of the paragraph list the code was dropped to (exceptions or requirements)
 * @returns {AppThunk}
 */
export const addFromCodebookThunk =
    (
        sourceIndex: number,
        destinationIndex: number,
        targetColumn: ExceptionsRequirementsPanelTypes
    ): AppThunk =>
    async (dispatch, getState) => {
        const { codeTemplates, lookupInput, targetSection } = getState().examCodeBookData;
        const currentOrder = getState().currentExamOrderData.currentExamOrder.id;

        const filterBySection = (template: CodeTemplate) => {
            if (targetSection === null) return true;
            return template.section === targetSection;
        };
        const handleFilterCodes = (template: CodeTemplate) => {
            if (!lookupInput) return true;
            else if (
                template.code?.toUpperCase().includes(lookupInput.toUpperCase()) ||
                template.body?.toUpperCase().includes(lookupInput.toUpperCase()) ||
                template.label?.toUpperCase().includes(lookupInput.toUpperCase())
            ) {
                return true;
            }
            return false;
        };
        const codeToAdd = codeTemplates
            .filter(filterBySection)
            .filter(handleFilterCodes)
            .sort(sortCodeTemplatesByAlphabet)
            .find((code, index) => index === sourceIndex);
        dispatch(
            addParagraphThunk(currentOrder, [
                {
                    codeTemplateId: codeToAdd.id,
                    sequence: destinationIndex,
                    section:
                        targetColumn === ExceptionsRequirementsPanelTypes.Exceptions
                            ? Section.exception
                            : Section.requirement
                }
            ])
        );
    };

/**
 * Save the updated paragraph sequence to the BE
 * @returns {AppThunk}
 */
export const updateSequenceThunk = (): AppThunk => async (dispatch, getState) => {
    try {
        const { id: orderId } = getState().currentExamOrderData.currentExamOrder;
        const { paragraphsList } = getState().exceptionsData;
        const paragraphListCopy: Paragraph[] = JSON.parse(JSON.stringify(paragraphsList));
        const newSequence: ParagraphSequence = paragraphListCopy.reduce(
            (acc, curr) => ({ ...acc, [curr.id]: curr.sequence }),
            {} as ParagraphSequence
        );
        const exceptionsList = paragraphListCopy.filter((par) => par.section === 0);
        const requirementsList = paragraphListCopy.filter((par) => par.section === 1);
        exceptionsList.forEach((paragraph, index) => {
            newSequence[paragraph.id] = index;
        });
        requirementsList.forEach((paragraph, index) => {
            newSequence[paragraph.id] = index;
        });
        dispatch(updateSequenceListThunk(orderId, newSequence));
    } catch (err) {
        dispatch(
            setSnackbarState({
                open: true,
                message: `Update paragraph sequence: ${err.message}`,
                severity: SnackbarSeverity.Error
            })
        );
    }
};

export default exceptionsSlice.reducer;
