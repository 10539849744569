import api from 'core/api/api';
import { SearchReport } from 'types/searchReport';

/**
 * Get search report for current exam order
 * @function
 * @category API
 * @param {string} orderId - Current order id
 * @returns {SearchReport}
 */
export const getExamOrderSearchReport = async (orderId: string): Promise<SearchReport> => {
    try {
        const response = await api.get<SearchReport>(`/api/examOrder/${orderId}/searchReport`);
        return response.data;
    } catch (err) {
        throw new Error(err.message);
    }
};
