import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderDetailsErrorData } from 'types/dataModels';

/**
 * Slices should be grouped by models in which they are saved and stored. The exception to this
 * would be grouping, so if there is a grouping of fields on the frontend that are not all saved
 * in the same model, then we will need to go about that specific use case differently
 */

interface OrderDetailsValidationState {
    orderDetailsErrorsData: OrderDetailsErrorData;
}

const emptyError = {
    feWarning: '',
    beWarning: ''
};

const reqError = {
    feWarning: 'Required Field',
    beWarning: ''
};

const orderDetails = {
    properties: {
        '0': {
            streetNumberError: emptyError,
            streetDirectionError: emptyError,
            streetNameError: emptyError,
            streetSuffixError: emptyError,
            unitError: emptyError,
            cityError: emptyError,
            stateError: reqError,
            zipError: emptyError,
            countyError: reqError
        }
    },
    generalInfoErrorsData: {
        businessSegmentError: emptyError,
        productTypeError: emptyError
    }
};

const initialState: OrderDetailsValidationState = {
    orderDetailsErrorsData: orderDetails
};

const orderDetailsValidationSlice = createSlice({
    name: 'OrdDetailsValidationForm',
    initialState,
    reducers: {
        /**
         * Will set the initial state of input fields on the workbench
         * @param state Slice state
         * @param action Payload with errors data to set
         */
        setOrderDetailsValidationErrors(state, action: PayloadAction<OrderDetailsErrorData>) {
            state.orderDetailsErrorsData = action.payload;
        },
        /**
         * Reset the input field errors and required fields back to the initial state
         * @param state Slice state
         */
        clearOrderDetailsValidationErrors(state) {
            state.orderDetailsErrorsData = initialState.orderDetailsErrorsData;
        }
    }
});

export const { setOrderDetailsValidationErrors, clearOrderDetailsValidationErrors } =
    orderDetailsValidationSlice.actions;

export default orderDetailsValidationSlice.reducer;
