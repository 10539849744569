import { v4 as uuidv4 } from 'uuid';
import {
    DocumentAddReferenceDto,
    NewKeyDocumentForm,
    PulseDocumentParty,
    UpdateRecordedDocumentDto,
    KeyDocument
} from 'types/dataModels';

/**
 * Update data for existing recorded document from user input form
 * @function
 * @param {string} categoryId - Document category id
 * @param {string} subCategoryId - Documents subcategory id
 * @param {NewKeyDocumentForm} documentData - Data from input form
 * @param {string} propertyId - Document property id
 * @param {KeyDocument} document - key document row data
 * @returns {UpdateRecordedDocumentDto}
 */
export const updateDataForRecordedExistingDocument = (
    categoryId: string,
    subCategoryId: string,
    documentData: NewKeyDocumentForm,
    propertyId: string,
    document: KeyDocument
): {
    isFileUpdated: boolean;
    uploadRequestPayload: UpdateRecordedDocumentDto | FormData;
} => {
    /**
     * Get number[] of id's from CodeTemplate[]
     */
    const codeTemplateIds: number[] = documentData.codes.reduce(
        (acc, current) => [...acc, current.id],
        []
    );

    /**
     * Convert ExamOrderReference[] to DocumentAddReferenceDto[]
     */
    const existingReferences: DocumentAddReferenceDto[] =
        documentData.foundReferencedDocuments.map((ref) => {
            return {
                referenceToDocumentId: ref.referenceToDocumentId,
                documentReferenceId: ref.documentReferenceId,
                instrumentNumber: '',
                instrumentYear: null,
                liber: '',
                page: ''
            };
        });

    /**
     * Create DocumentAddReferenceDto[] from input received from user for not existing references for current order
     */
    const notExistingReferences: DocumentAddReferenceDto[] =
        documentData.notExistingReferencedDocuments.map((ref) => {
            return {
                referenceToDocumentId: null,
                documentReferenceId: ref.documentReferenceId ?? null,
                instrumentNumber: ref.instrumentNumber,
                instrumentYear: ref.instrumentYear,
                liber: ref.book,
                page: ref.page
            };
        });

    /**
     * Concat existing and not existing references
     */
    const references: DocumentAddReferenceDto[] = [
        ...existingReferences,
        ...notExistingReferences
    ];

    /**
     * Convert PulseDocumentPartyField[] to PulseDocumentParty[]
     */
    const parties: PulseDocumentParty[] = documentData.parties.reduce(
        (acc, currentValue) => [
            ...acc,
            {
                isGrantee: currentValue.isGrantee,
                role: currentValue.role,
                fullLegal: currentValue.displayValue
            }
        ],
        [] as PulseDocumentParty[]
    );

    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const fileData: any = documentData.files[documentData.files.length - 1];
    let isFileUpdated = false;
    let uploadRequestPayload = null;
    if (fileData.id === document.files[0].id && fileData.name === document.files[0].fileName) {
        isFileUpdated = false;
        uploadRequestPayload = updateDataForRecordedDocument(
            categoryId,
            subCategoryId,
            documentData,
            propertyId,
            document,
            codeTemplateIds,
            parties,
            references
        );
    } else {
        isFileUpdated = true;
        uploadRequestPayload = updateDataForRecordedDocumentWithFiles(
            categoryId,
            subCategoryId,
            documentData,
            propertyId,
            document,
            codeTemplateIds,
            parties,
            references
        );
    }

    return {
        isFileUpdated,
        uploadRequestPayload
    };
};

export const updateDataForRecordedDocumentWithFiles = (
    categoryId: string,
    subCategoryId: string,
    documentData: NewKeyDocumentForm,
    propertyId: string,
    document: KeyDocument,
    codeTemplateIds: number[],
    parties: PulseDocumentParty[],
    references: DocumentAddReferenceDto[]
): FormData => {
    const formData: FormData = new FormData();
    formData.set('Files', documentData.files[0] || JSON.stringify([]));
    formData.set('Id', document.id);
    formData.set('PropertyId', propertyId);
    formData.set('DocumentType.DocumentCategoryId', categoryId);
    formData.set('DocumentType.DocumentSubCategoryId', subCategoryId);
    formData.set(
        'DocumentType.SourceDocumentTypeName',
        document?.documentType?.sourceDocumentTypeName ?? ''
    );
    formData.set('BookType', `${document.bookType ?? ''}`);
    formData.set('DocumentNumber', `${document?.documentNumber ?? ''}`);
    formData.set('InstrumentNumber', `${documentData?.instrumentNumber ?? ''}`);
    formData.set('Liber', `${documentData?.book ?? ''}`);
    formData.set('Page', `${documentData?.page ?? ''}`);
    formData.set('InstrumentYear', `${documentData?.instrumentYear}`);
    formData.set('RecordedDate', `${documentData?.recordedDate ?? ''}`);
    formData.set('DatedDate', `${documentData?.instrumentDate ?? ''}`);
    formData.set('Notes', documentData?.notes ?? '');
    formData.set('Amount', `${documentData?.amount ?? 0}`);
    formData.set('TransferTaxAmount', `${documentData?.transferTaxAmount ?? 0}`);
    formData.set(
        'DocumentSource.DataProviderSourceName',
        `${document?.examinerDataSourceDisplayValue}`
    );

    codeTemplateIds.forEach((codeId) => {
        formData.append('CodeTemplateIds', `${codeId}`);
    });

    parties.forEach((party) => {
        formData.append(party.isGrantee ? 'Grantees' : 'Grantors', party.fullLegal);
        formData.append(
            party.isGrantee ? 'GranteeRoles' : 'GrantorRoles',
            party.isGrantee ? 'Grantee' : 'Grantor'
        );
    });

    references.forEach((reference) => {
        formData.append('DocRefDocumentReferenceId', `${reference.documentReferenceId ?? ''}`);
        formData.append(
            'DocRefReferenceToDocumentId',
            `${reference.referenceToDocumentId ?? ''}`
        );
        formData.append('DocRefInstrumentNumber', `${reference.instrumentNumber}`);
        formData.append('DocRefInstrumentYear', `${reference.instrumentYear ?? 0}`);
        formData.append('DocRefLiber', `${reference.liber}`);
        formData.append('DocRefPage', `${reference.page}`);
    });

    return formData;
};

const updateDataForRecordedDocument = (
    categoryId: string,
    subCategoryId: string,
    documentData: NewKeyDocumentForm,
    propertyId: string,
    document: KeyDocument,
    codeTemplateIds: number[],
    parties: PulseDocumentParty[],
    references: DocumentAddReferenceDto[]
): UpdateRecordedDocumentDto => {
    const updateObj: UpdateRecordedDocumentDto = {
        id: document.id,
        bookType: document.bookType,
        documentType: {
            documentCategoryId: categoryId,
            documentSubCategoryId: subCategoryId,
            sourceDocumentTypeName: document?.documentType?.sourceDocumentTypeName ?? ''
        },
        propertyId,
        instrumentNumber: documentData.instrumentNumber,
        liber: documentData.book,
        page: documentData.page,
        codeTemplateIds: codeTemplateIds,
        instrumentYear: documentData.instrumentYear,
        notes: document?.notes?.length
            ? [{ id: document?.notes[0]?.id, note: documentData?.notes }]
            : documentData.notes
            ? [{ id: uuidv4(), note: documentData?.notes }]
            : [],
        amount: documentData?.amount,
        transferTaxAmount: documentData.transferTaxAmount,
        references,
        parties,
        datedDate: documentData.instrumentDate,
        recordedDate: documentData.recordedDate,
        effectiveDate: documentData.instrumentDate,
        documentSource: {
            dataProviderSourceName: document.examinerDataSourceDisplayValue
        },
        documentNumber: document.documentNumber,
        documentLegalDescription: document.documentLegalDescription
    };

    return updateObj;
};
