import { v4 as uuidv4 } from 'uuid';
import {
    CreatePulseDocumentFileDto,
    DocumentAddReferenceDto,
    NewKeyDocumentForm,
    NewRecordedDocumentWithFilesDto,
    NewCommonDocumentWithFilesDto,
    PulseDocumentParty
} from 'types/dataModels';

/**
 * Create data for upload recorded document from user input form
 * @function
 * @param {CreatePulseDocumentFileDto[]} uploadedFiles - Array of files for upload
 * @param {string} categoryId - Document category id
 * @param {string} subCategoryId - Documents subcategory id
 * @param {NewKeyDocumentForm} documentData - Data from input form
 * @param {string} propertyId - Document property id
 * @param {string} searchType - Document search type
 * @param {string} argumentsCompositeValue - Document argument composite value
 * @returns {NewRecordedDocumentWithFilesDto}
 */
export const createDataForRecordedDocumentUpload = (
    uploadedFiles: CreatePulseDocumentFileDto[],
    categoryId: string,
    subCategoryId: string,
    documentData: NewKeyDocumentForm,
    propertyId: string,
    searchType: string,
    argumentsCompositeValue: string
): NewRecordedDocumentWithFilesDto => {
    /**
     * Get number[] of id's from CodeTemplate[]
     */
    const codeTemplateIds: number[] = documentData.codes.reduce(
        (acc, current) => [...acc, current.id],
        []
    );

    /**
     * Convert ExamOrderReferencedDocument[] to DocumentAddReferenceDto[]
     */
    const existingReferencedDocuments: DocumentAddReferenceDto[] =
        documentData.foundReferencedDocuments.map((ref) => {
            return {
                referenceToDocumentId: ref.id,
                instrumentNumber: '',
                instrumentYear: null,
                liber: '',
                page: ''
            };
        });

    /**
     * Create DocumentAddReferenceDto[] from input received from user for not existing references for current order
     */
    const notExistingReferencedDocuments: DocumentAddReferenceDto[] =
        documentData.notExistingReferencedDocuments.map((ref) => {
            return {
                referenceToDocumentId: null,
                instrumentNumber: ref.instrumentNumber,
                instrumentYear: ref.instrumentYear,
                liber: ref.book,
                page: ref.page
            };
        });

    /**
     * Concat existing and not existing references
     */
    const references: DocumentAddReferenceDto[] = [
        ...existingReferencedDocuments,
        ...notExistingReferencedDocuments
    ];

    /**
     * Convert PulseDocumentPartyField[] to PulseDocumentParty[]
     */
    const parties: PulseDocumentParty[] = documentData.parties.reduce(
        (acc, currentValue) => [
            ...acc,
            {
                isGrantee: currentValue.isGrantee,
                role: currentValue.role,
                fullLegal: currentValue.displayValue
            }
        ],
        [] as PulseDocumentParty[]
    );
    return {
        documentType: {
            documentCategoryId: categoryId,
            documentSubCategoryId: subCategoryId,
            sourceDocumentTypeName: ''
        },
        propertyId,
        documentSource: {
            isSearch: false,
            examinerSearchType: searchType ?? '',
            searchArgumentsCompositeValue: argumentsCompositeValue ?? ''
        },
        instrumentNumber: documentData?.instrumentNumber ?? '',
        instrumentYear: documentData.instrumentYear,
        bookType: '',
        liber: documentData?.book ?? '',
        page: documentData?.page ?? '',
        documentNumber: documentData?.instrumentNumber ?? '',
        recordedDate: documentData?.recordedDate === '' ? null : documentData?.recordedDate,
        datedDate: documentData?.instrumentDate === '' ? null : documentData?.instrumentDate,
        effectiveDate: null,
        parties: parties,
        documentLegalDescription: '',
        notes: documentData?.notes !== '' ? [{ id: uuidv4(), note: documentData?.notes }] : [],
        codeTemplateIds: codeTemplateIds,
        files: uploadedFiles,
        references,
        amount: documentData.amount,
        transferTaxAmount: documentData.transferTaxAmount
    };
};

export const createDataForCommonDocumentUpload = (
    uploadedFiles: CreatePulseDocumentFileDto[],
    categoryId: string,
    subCategoryId: string,
    documentData: NewKeyDocumentForm,
    propertyId: string
): NewCommonDocumentWithFilesDto => {
    /**
     * Get number[] of id's from CodeTemplate[]
     */
    const codeTemplateIds: number[] = documentData.codes.reduce(
        (acc, current) => [...acc, current.id],
        []
    );

    /**
     * Convert PulseDocumentPartyField[] to PulseDocumentParty[]
     */
    const parties: PulseDocumentParty[] = documentData.parties.reduce(
        (acc, currentValue) => [
            ...acc,
            {
                isGrantee: currentValue.isGrantee,
                role: currentValue.role,
                fullLegal: currentValue.displayValue
            }
        ],
        [] as PulseDocumentParty[]
    );
    return {
        documentType: {
            documentCategoryId: categoryId,
            documentSubCategoryId: subCategoryId,
            sourceDocumentTypeName: ''
        },
        propertyId,
        documentSource: null,
        instrumentNumber: documentData?.instrumentNumber ?? '',
        instrumentYear: documentData.instrumentYear,
        bookType: '',
        liber: documentData?.book ?? '',
        page: documentData?.page ?? '',
        documentNumber: documentData?.instrumentNumber ?? '',
        recordedDate: documentData?.recordedDate === '' ? null : documentData?.recordedDate,
        datedDate: documentData?.instrumentDate === '' ? null : documentData?.instrumentDate,
        effectiveDate: null,
        parties: parties,
        documentLegalDescription: '',
        notes: documentData?.notes !== '' ? [{ id: uuidv4(), note: documentData?.notes }] : [],
        codeTemplateIds: codeTemplateIds,
        files: uploadedFiles
    };
};
