import { ExamOrder } from 'types/examOrderDataModel';
import api from 'core/api/api';
import { ProjectOrder } from 'types/dataModels';

/**
 * It makes a GET request to get exam order object
 * @function
 * @category API
 * @subcategory examOrder
 * @param {string} orderId - The id of the exam order you want to get.
 * @returns {ExamOrder}
 */
export const getExamOrder = async (orderId: string): Promise<ExamOrder> => {
    try {
        const response = await api.get<ExamOrder>(`/api/examOrder/${orderId}`);
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};

/**
 * Update effective date
 * @function
 * @category API
 * @subcategory examOrder
 * @param {string} orderId - The id of the order you want to update
 * @param {string} effectiveDate - The new effective date value.
 * @returns {ExamOrder}
 */
export const updateEffectiveDate = async (
    orderId: string,
    effectiveDate: string
): Promise<ExamOrder> => {
    try {
        const response = await api.put<null, ExamOrder>(
            `/api/examOrder/${orderId}/effectiveDate?effectiveDate=${effectiveDate}`
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};

/**
 * It makes a PUT request to the server to update the status of the order to complete
 * @function
 * @category API
 * @subcategory examOrder
 * @param {string} orderId - string - The id of the order you want to complete
 * @returns {ProjectOrder} The order that was completed.
 */
export const completeOrder = async (orderId: string): Promise<ProjectOrder> => {
    try {
        const response = await api.put<null, ProjectOrder>(
            `/api/orderProject/${orderId}/status/complete`
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};
