import { NewTaxDocument, Tax } from 'types/taxes';
import { CreatePulseDocumentFileDto } from 'types/dataModels';
import api from '../api';

/**
 * Uploads a new tax document
 * @function
 * @category API
 * @param {string} orderId
 * @param {CreatePulseDocumentFileDto[]} uploadedFiles
 * @param {NewTaxDocument} newTaxData
 * @param {string} categoryId
 * @param {string} subCategoryId
 * @param {number[]} codes
 * @returns {Tax}
 */
export const uploadTaxWithNoImage = async (
    orderId: string,
    uploadedFiles: CreatePulseDocumentFileDto[],
    newTaxData: NewTaxDocument,
    categoryId: string,
    subCategoryId: string,
    codes: number[]
): Promise<Tax> => {
    try {
        const newTaxDocument: NewTaxDocument = {
            ...newTaxData,
            documentType: {
                ...newTaxData.documentType,
                documentCategoryId: categoryId,
                documentSubCategoryId: subCategoryId
            },
            codeTemplateIds: codes,
            files: uploadedFiles
        };
        const response = await api.post<NewTaxDocument, Tax>(
            `/api/examOrder/${orderId}/document/tax/withNoImage`,
            newTaxDocument
        );
        return response.data;
    } catch (e) {
        if (e.response !== null) {
            if (e.response.data !== null) {
                if (e.response.data.Message !== null) {
                    throw new Error(e.response.data.Message);
                }
            }
        }
        throw new Error(e.message);
    }
};
