import api from 'core/api/api';
import { BusinessSegment } from 'types/dataModels';

/**
 * This function makes a GET request to the server and returns the response data as an array of
 * BusinessSegment objects.
 * @function
 * @category API
 * @subcategory businessSegment
 * @returns {BusinessSegment[]} An array of BusinessSegment objects.
 */
export const getBusinessSegments = async (): Promise<BusinessSegment[]> => {
    try {
        const response = await api.getWithCache<BusinessSegment[]>('/api/businessSegment');
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};
