import api from 'core/api/api';

/**
 * Get TSRI report for order (file)
 * @function
 * @category API
 * @param {string} orderId - current order id value
 * @returns {{ buffer: ArrayBuffer, type: string }}
 */
export const getTSRReport = async (
    orderId: string
): Promise<{ buffer: ArrayBuffer; type: string }> => {
    try {
        const response = await api.getPdfFile<ArrayBuffer>(
            `/api/remitOrder/${orderId}/tsrReport`
        );
        return { buffer: response.data, type: response.headers['content-type'] };
    } catch (e) {
        throw new Error(e.message);
    }
};
