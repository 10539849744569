import {
    DocumentCategorySubCategoryDto,
    DocumentCategorySubCategoryResponse,
    DocumentCode,
    PulseDocumentParty,
    PulseDocumentPartyField,
    PulseDocumentPartyFieldWithPara,
    UpdateKeyDocumentInstrument,
    WarningsResponse,
    RecordedDateWithPara,
    AmountsWithPara,
    UpdateKeyDocumentInstrumentWithPara
} from 'types/dataModels';
import api from '../api';

/**
 * Add a new party to document.
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - string - The id of the order,
 * @param {string} documentId - string - The id of the document,
 * @param {PulseDocumentPartyField} newParty - PulseDocumentPartyField
 * @returns {PulseDocumentPartyField}
 */
export const addDocumentPartyApi = async (
    orderId: string,
    documentId: string,
    newParty: PulseDocumentPartyField
): Promise<PulseDocumentPartyField> => {
    try {
        const response = await api.post<PulseDocumentParty, PulseDocumentPartyField>(
            `/api/examOrder/${orderId}/document/${documentId}/fields/partydto`,
            newParty
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};

/**
 * This function deletes a party from a document in an order.
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - string - The id of the order,
 * @param {string} documentId - string - The id of the document,
 * @param {string} partyId - string - Target party id
 * @returns {string} OK.
 */
export const deleteDocumentPartyApi = async (
    orderId: string,
    documentId: string,
    partyId: string
): Promise<string> => {
    try {
        const response = await api.delete(
            `/api/examOrder/${orderId}/document/${documentId}/fields/partydto/${partyId}`
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};

/**
 * This function deletes a party from a document in an order.
 * tells us which paragraphs are affected by this party delete
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - string - The id of the order,
 * @param {string} documentId - string - The id of the document,
 * @param {string} partyId - string - Target party id
 * @returns {string} OK.
 */
export const deleteDocumentPartyWithParagraphsApi = async (
    orderId: string,
    documentId: string,
    partyId: string
): Promise<string> => {
    try {
        const response = await api.delete(
            `/api/examOrder/${orderId}/document/${documentId}/fields/partydto/${partyId}/withParagraphs`
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};

/**
 * Update party in document
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - string - The id of the order,
 * @param {string} documentId - string - The id of the document in order,
 * @param {string} partyId - string - The id of the party in document,
 * @param {string} examValue - new display value
 * @returns {PulseDocumentPartyField}
 */
export const updateDocumentPartyApi = async (
    orderId: string,
    documentId: string,
    partyId: string,
    examValue: string
): Promise<PulseDocumentPartyField> => {
    try {
        const response = await api.put<string, PulseDocumentPartyField>(
            `/api/examOrder/${orderId}/document/${documentId}/fields/partydto/${partyId}?examValue=${encodeURIComponent(
                examValue
            )}`
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};

/**
 * Update party in document, this includes updates to
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - string - The id of the order,
 * @param {string} documentId - string - The id of the document in order,
 * @param {string} partyId - string - The id of the party in document,
 * @param {string} examValue - new display value
 * @returns {PulseDocumentPartyFieldWithPara}
 */
export const updateDocumentPartyWithParagraphsApi = async (
    orderId: string,
    documentId: string,
    partyId: string,
    examValue: string
): Promise<PulseDocumentPartyFieldWithPara> => {
    try {
        const response = await api.put<string, PulseDocumentPartyFieldWithPara>(
            `/api/examOrder/${orderId}/document/${documentId}/fields/partydto/${partyId}/withParagraphs?examValue=${encodeURIComponent(
                examValue
            )}`
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};

/**
 * This function updates the recorded date of a document in an exam order.
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - string - The id of the order,
 * @param {string} documentId - string - The id of the document in order,
 * @param {string} examValue - string - The new recorded date
 * @returns {{value:string}}
 */
export const updateDocumentRecordedDateApi = async (
    orderId: string,
    documentId: string,
    examValue: string
): Promise<{ value: string; warnings: WarningsResponse[] }> => {
    try {
        const response = await api.put<null, { value: string; warnings: WarningsResponse[] }>(
            `/api/examOrder/${orderId}/document/${documentId}/fields/recordedDate?examValue=${examValue}`
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};

/**
 * This function updates the recorded date of a document in an exam order.
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - string - The id of the order,
 * @param {string} documentId - string - The id of the document in order,
 * @param {string} examValue - string - The new recorded date
 * @returns {{value:string}}
 */
export const updateDocumentRecordedDateWithParagraphsApi = async (
    orderId: string,
    documentId: string,
    examValue: string
): Promise<RecordedDateWithPara> => {
    try {
        const response = await api.put<null, RecordedDateWithPara>(
            `/api/examOrder/${orderId}/document/${documentId}/fields/recordedDate/withParagraphs?examValue=${examValue}`
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};

/**
 * This function updates the instrumentId field of a document in an exam order
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - string - The order id of the order you want to update
 * @param {string} documentId - The id of the document you want to update
 * @param {UpdateKeyDocumentInstrument} instrumentObject - new value of instrument
 * @returns {UpdateKeyDocumentInstrument}
 */
export const updateDocumentInstrumentApi = async (
    orderId: string,
    documentId: string,
    instrumentObject: UpdateKeyDocumentInstrument
): Promise<UpdateKeyDocumentInstrument> => {
    try {
        const response = await api.put<
            UpdateKeyDocumentInstrument,
            UpdateKeyDocumentInstrument
        >(
            `/api/examOrder/${orderId}/document/${documentId}/fields/instrumentId`,
            instrumentObject
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};

/**
 * This function updates the instrumentId field of a document in an exam order,
 * returns the updated paragraphs as well if there were any
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - string - The order id of the order you want to update
 * @param {string} documentId - The id of the document you want to update
 * @param {UpdateKeyDocumentInstrument} instrumentObject - new value of instrument
 * @returns {UpdateKeyDocumentInstrumentWithPara}
 */
export const updateDocumentInstrumentWithParagraphsApi = async (
    orderId: string,
    documentId: string,
    instrumentObject: UpdateKeyDocumentInstrument
): Promise<UpdateKeyDocumentInstrumentWithPara> => {
    try {
        const response = await api.put<
            UpdateKeyDocumentInstrument,
            UpdateKeyDocumentInstrumentWithPara
        >(
            `/api/examOrder/${orderId}/document/${documentId}/fields/instrumentId/withParagraphs`,
            instrumentObject
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};

/**
 * Add codes to document
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - string - The id of the order
 * @param {string} documentId - string - The id of the document to add codes to
 * @param {number[]} codeIds - number[] - an array of code ids to add to the document
 * @returns {DocumentCode[]}
 */
export const addDocumentCodes = async (
    orderId: string,
    documentId: string,
    codeIds: number[]
): Promise<DocumentCode[]> => {
    try {
        const response = await api.post<number[], DocumentCode[]>(
            `/api/examOrder/${orderId}/document/${documentId}/addCodes`,
            codeIds
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};

/**
 * Set codes to document (override)
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - string - The id of the order
 * @param {string} documentId - The id of the document you want to set codes for
 * @param {number[]} codeIds - number[]
 * @returns {DocumentCode[]}
 */
export const setDocumentCodes = async (
    orderId: string,
    documentId: string,
    codeIds: number[]
): Promise<DocumentCode[]> => {
    try {
        const response = await api.post<number[], DocumentCode[]>(
            `/api/examOrder/${orderId}/document/${documentId}/setCodes`,
            codeIds
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};

/**
 * It updates the amount field of a document in an order
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - The id of the order
 * @param {string} documentId - The id of the document you want to update
 * @param {number} amount - number - The new value of amount
 * @returns {{ value: number }}
 */
export const updateAmountField = async (
    orderId: string,
    documentId: string,
    amount: number
): Promise<{ value: number }> => {
    try {
        const response = await api.put<{ value: number }, { value: number }>(
            `/api/examOrder/${orderId}/document/${documentId}/fields/amount`,
            { value: amount }
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};

/**
 * It updates the amount field of a document in an order,
 * returns the pragraphs that were updated as a result of this field changing
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - The id of the order
 * @param {string} documentId - The id of the document you want to update
 * @param {number} amount - number - The new value of amount
 * @returns {{ value: number }}
 */
export const updateAmountFieldWithParagraphs = async (
    orderId: string,
    documentId: string,
    amount: number
): Promise<AmountsWithPara> => {
    try {
        const response = await api.put<{ value: number }, AmountsWithPara>(
            `/api/examOrder/${orderId}/document/${documentId}/fields/amount/withParagraphs`,
            { value: amount }
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};

/**
 * It updates the transfer tax amount field of a document in an order
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - The id of the order
 * @param {string} documentId - The id of the document you want to update.
 * @param {number} amount - number - The amount of the transfer tax amount
 * @returns {{ value: number }}
 */
export const updateTransferTaxAmountField = async (
    orderId: string,
    documentId: string,
    amount: number
): Promise<{ value: number }> => {
    try {
        const response = await api.put<{ value: number }, { value: number }>(
            `/api/examOrder/${orderId}/document/${documentId}/fields/transferTaxAmount`,
            { value: amount }
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};

/**
 * It updates the transfer tax amount field of a document in an order,
 * if the change to the amount involved a code, that updated code is returned in this response
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - The id of the order
 * @param {string} documentId - The id of the document you want to update.
 * @param {number} amount - number - The amount of the transfer tax amount
 * @returns {{ value: number }}
 */
export const updateTransferTaxAmountFieldWithParagraphs = async (
    orderId: string,
    documentId: string,
    amount: number
): Promise<AmountsWithPara> => {
    try {
        const response = await api.put<{ value: number }, AmountsWithPara>(
            `/api/examOrder/${orderId}/document/${documentId}/fields/transferTaxAmount/withParagraphs`,
            { value: amount }
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};

/**
 * It updates the amount field of an assessor document
 * @function
 * @category API
 * @subcategory examOrderDocumentFields
 * @param {string} orderId - The id of the order
 * @param {string} documentId - The id of the document you want to update.
 * @param {number} amount - number - The amount of the assessor amount
 * @returns {{ value: number }}
 */
export const updateAssessorAmountField = async (
    orderId: string,
    documentId: string,
    amount: number
): Promise<{ value: number }> => {
    try {
        const response = await api.put<{ value: number }, { value: number }>(
            `/api/examOrder/${orderId}/document/${documentId}/fields/assessorAmount`,
            { value: amount }
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};

/**
 * It sets categoy and subcategory of a document
 * @function
 * @category API
 * @param {DocumentCategorySubCategoryDto}
 * @returns {DocumentCategorySubCategoryResponse}
 */
export const setCategoryAndSubcategoryApi = async ({
    orderId,
    documentId,
    documentCategoryId,
    documentSubCategoryId,
    sourceDocumentTypeName
}: DocumentCategorySubCategoryDto): Promise<DocumentCategorySubCategoryResponse> => {
    try {
        const response = await api.put<
            DocumentCategorySubCategoryDto,
            DocumentCategorySubCategoryResponse
        >(`/api/examOrder/${orderId}/document/${documentId}/type`, {
            orderId,
            documentId,
            documentCategoryId,
            documentSubCategoryId,
            sourceDocumentTypeName
        });
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};
