import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IWorkbenchSidebarSlice {
    /**
     * collapsed/expanded workbench sidebar view
     */
    isOpen: boolean;
    /**
     * fullScreen/ordinary workbench sidebar view
     */
    isFullScreen: boolean;
    isPopoutSidebar: boolean;
    isCodeBookOpen: boolean;
}

const initialState: IWorkbenchSidebarSlice = {
    isOpen: false,
    isFullScreen: false,
    isPopoutSidebar: false,
    isCodeBookOpen: false
};

const workbenchSidebarSlice = createSlice({
    name: 'workbenchSidebar',
    initialState,
    reducers: {
        /**
         * Set collapsed/expanded sidebar view
         * @param state Slice state
         * @param action Payload with the isOpen value to set
         */
        setIsOpen(
            state: IWorkbenchSidebarSlice,
            action: PayloadAction<IWorkbenchSidebarSlice['isOpen']>
        ) {
            state.isOpen = action.payload;
        },
        /**
         * Set fullscreen/normal mode for sidebar
         * @param state Slice state
         * @param action Payload with the isFullScreen value to set
         */
        setIsFullScreenMode(
            state: IWorkbenchSidebarSlice,
            action: PayloadAction<IWorkbenchSidebarSlice['isFullScreen']>
        ) {
            state.isFullScreen = action.payload;
        },
        /**
         * Set popout mode for sidebar
         * @param state Slice state
         * @param action Payload with the isPopoutSidebar value to set
         */
        setIsPopoutSidebar(state: IWorkbenchSidebarSlice, action: PayloadAction<boolean>) {
            state.isPopoutSidebar = action.payload;
        },
        /**
         * Set open state for codebook
         * @param state Slice state
         * @param action Payload with the isCodeBookOpen value to set
         */
        setIsCodeBookOpen(state: IWorkbenchSidebarSlice, action: PayloadAction<boolean>) {
            state.isCodeBookOpen = action.payload;
        }
    }
});

export const { setIsOpen, setIsFullScreenMode, setIsPopoutSidebar, setIsCodeBookOpen } =
    workbenchSidebarSlice.actions;
export default workbenchSidebarSlice.reducer;
