import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocumentType, DocumentNote, PulseDocumentPartyField } from 'types/dataModels';
import { DocumentSource, Installment, NewTaxDocumentFormData } from 'types/taxes';

interface NewTaxState {
    newTax: NewTaxDocumentFormData;
}

export const initialState: NewTaxState = {
    newTax: {
        documentType: {} as DocumentType,
        propertyId: '',
        documentSource: {} as DocumentSource,
        installments: [] as Installment[],
        baseAmount: null,
        amountDue: null,
        amountPaid: null,
        foundDelinquent: null,
        dueDate: null,
        datePaid: null,
        delinquentDate: null,
        payDate: null,
        totalAssessment: null,
        appraisedValueLand: null,
        appraisedValueImprovement: null,
        appraisedValueTotal: null,
        assessedValueLand: null,
        assessedValueImprovement: null,
        assessedValueTotal: null,
        isHomestead: false,
        hasExemptions: false,
        exemptionAdditionalAmount: null,
        exemptionAmount: null,
        exemptionHomesteadSupplierAmount: null,
        exemptionMortgageAmount: null,
        exemptionOtherAmount: null,
        taxVolume: null,
        saleDate: null,
        notes: [] as DocumentNote[],
        codeTemplateIds: null,
        instrumentNumber: null,
        instrumentYear: null,
        bookType: '',
        liber: '',
        page: '',
        documentNumber: null,
        recordedDate: null,
        datedDate: null,
        effectiveDate: null,
        parties: [] as PulseDocumentPartyField[],
        documentLegalDescription: null,
        city: null
    }
};

const newTaxSlice = createSlice({
    name: 'newTax',
    initialState,
    reducers: {
        /**
         * Set the fields of the current new tax form
         * @param state Slice state
         * @param action Payload with the new tax form data
         */
        setNewTax(state, action: PayloadAction<NewTaxDocumentFormData>) {
            state.newTax = action.payload;
        },
        /**
         * Set the property the new tax is associated to
         * @param state Slice state
         * @param action Payload with the ID of the property
         */
        setNewTaxCurrentProperty(state, action: PayloadAction<string>) {
            state.newTax.propertyId = action.payload;
        },
        /**
         * Update the parties of the new tax
         * @param state Slice state
         * @param action Payload with the list of parties to set
         */
        updateTaxDocumentParties(state, action: PayloadAction<PulseDocumentPartyField[]>) {
            state.newTax.parties = action.payload;
        },
        /**
         * Update the installments of the new tax
         * @param state Slice state
         * @param action Payload with the list of installments to set
         */
        updateTaxDocumentInstallments(state, action: PayloadAction<Installment[]>) {
            state.newTax.installments = action.payload;
        }
    }
});

export const {
    setNewTax,
    setNewTaxCurrentProperty,
    updateTaxDocumentParties,
    updateTaxDocumentInstallments
} = newTaxSlice.actions;
export default newTaxSlice.reducer;
