import { County } from 'types/dataModels';
import api from '../api';

/**
 * Get county list depend on state
 * @function
 * @category API
 * @param {string} stateId State id value
 */
export const getStateCounty = async (stateId: number): Promise<County[]> => {
    try {
        const response = await api.getWithCache<County[]>(`/api/state/${stateId}/county`);
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};
