import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import MsalAuthService from 'core/services/MsalAuthService';

/**
 * Page for redirection to auth service
 * @component
 * @returns {JSX.Element}
 */
export default function UnauthorizedView() {
    const { instance } = useMsal();
    const msalConfigReq = async () => await MsalAuthService.getLoginRequestParams();

    useEffect(() => {
        if (instance) {
            msalConfigReq().then((config) =>
                instance.loginRedirect(config).catch((e) => {
                    console.error(e);
                })
            );
        }
    }, [instance]);

    return <>Redirecting...</>;
}
