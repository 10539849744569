import { ReactElement, SyntheticEvent } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import MsalInstanceProvider from 'components/common/MsalInstanceProvider';
import LoadingSpinner from 'components/common/LoadingSpinner';
import Header from 'components/common/AppHeader';
import LeftSidebar from 'components/common/LeftSidebar';
import AuthorizedView from 'containers/AuthorizedView';
import UnauthorizedView from 'containers/UnauthorizedView';
import { useAppSelector } from 'core/hooks/useAppSelector';
import { useAppDispatch } from 'core/hooks/useAppDispatch';
import { setSnackbarClose } from 'core/features/snackbar/snackbarSlice';
import { SnackbarCloseReason } from 'core/constants/common';
import { StyledEngineProvider } from '@mui/material/styles';

function App(): ReactElement {
    const dispatch = useAppDispatch();
    const { open, message, autoHideDuration, severity, anchorOrigin, alertVariant } =
        useAppSelector((state) => state.snackbarData);

    /**
     * Callback fired when the component requests to be closed.
     * @param {SyntheticEvent | Event} event
     * @param {string} reason
     */
    const snackbarOnCloseHandler = (event?: SyntheticEvent | Event, reason?: string) => {
        if (reason === SnackbarCloseReason.Clickaway) {
            return;
        }
        dispatch(setSnackbarClose());
    };

    return (
        <StyledEngineProvider injectFirst>
            <MsalInstanceProvider>
                <>
                    <AuthenticatedTemplate>
                        <div className="content">
                            <BrowserRouter>
                                <Header />
                                <LeftSidebar />
                                <section className="mainContainer">
                                    <LoadingSpinner />
                                    <AuthorizedView />
                                </section>
                            </BrowserRouter>
                        </div>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <UnauthorizedView />
                    </UnauthenticatedTemplate>
                </>
            </MsalInstanceProvider>
            <Snackbar
                open={open}
                autoHideDuration={autoHideDuration}
                onClose={snackbarOnCloseHandler}
                anchorOrigin={anchorOrigin}>
                <Alert
                    variant={alertVariant}
                    onClose={snackbarOnCloseHandler}
                    severity={severity}
                    sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </StyledEngineProvider>
    );
}
export default App;
