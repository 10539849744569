import api from 'core/api/api';
import { InterestEstateType } from 'types/dataModels';

/**
 * Get interest estate types array
 * @function
 * @category API
 * @returns {InterestEstateType[]}
 */
export const getInterestEstateType = async (): Promise<InterestEstateType[]> => {
    try {
        const response = await api.getWithCache<InterestEstateType[]>(
            '/api/interestEstateType'
        );
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};
