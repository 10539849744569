import api from 'core/api/api';
import { ProductType } from 'types/dataModels';

/**
 * Get the list of product types
 * @function
 * @category API
 * @returns {ProductType[]}
 */
export const getProductTypes = async (): Promise<ProductType[]> => {
    try {
        const response = await api.getWithCache<ProductType[]>('/api/productType');
        return response.data;
    } catch (e) {
        throw new Error(e.message);
    }
};
